const Axios = require("axios");
const Constant = require("../Constant");
const { getTokenDetails } = require("../utilities/CommonFunction");

export async function getBanners(data = {}) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.post(
    Constant.apiBasePath + "banner/get-all",
    data,
    { headers: { token: token } }
  );
  return response.data;
}

export async function deleteAccount(id) {
  let token = await localStorage.getItem("loginDetails");
  console.log(token);
  let response = await Axios.delete(
    Constant.apiBasePath + `user/delete/${id}`,
    { headers: { token: token } }
  );
  return response.data;
}

export async function getCountries() {
  let response = await Axios.get(
    Constant.apiBasePath + "location/country/getAll"
  );
  return response.data;
}

export async function getStatesbyCountry(prams = {}) {
  let response = await Axios.get(
    Constant.apiBasePath + "location/state/getAll",
    {
      params: prams,
    }
  );
  return response.data;
}

export async function getCitiesbyState(params = {}) {
  let response = await Axios.get(
    Constant.apiBasePath + "location/city/getAll",
    {
      params: params,
    }
  );
  return response.data;
}
export async function getContests(params = {}) {
  let response = await Axios.get(
    Constant.apiBasePath + "contest/getDataForApp",
    {
      params: params,
    }
  );
  return response.data;
}
export async function Register(data) {
  let response = await Axios.post(Constant.apiBasePath + "register", data);
  return response.data;
}

export async function updateUserProfile(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.patch(
    Constant.apiBasePath + "updateUserInfoById",
    data,
    { headers: { token: token } }
  );
  return response.data;
}

export async function updateUserPassword(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.patch(
    Constant.apiBasePath + "updatePassword",
    data,
    { headers: { token: token } }
  );

  return response.data;
}

export async function getEnrollmentDataForApp(params) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(
    Constant.apiBasePath + "enrollment/getDataForApp?userId=" + params.userId,
    { headers: { token: token } }
  );
  return response.data;
}

export async function getUserPlan() {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(
    Constant.apiBasePath + "enrollment/getUserPlan",
    { headers: { token: token } }
  );
  return response.data;
}

export async function createUserSubscription(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.post(
    Constant.apiBasePath + "order/create-user-subscription",
    data,
    { headers: { token: token } }
  );
  return response.data;
}

export async function getStoryCategories(prams = {}) {
  console.log(prams);
  let token = localStorage.getItem("loginDetails");
  console.log(token);

  let response = await Axios.get(Constant.apiBasePath + "category/view-all", {
    headers: { token: token },
    params: prams,
  });

  console.log("response========>");
  console.log(response);

  return response.data;
}
export async function getLanguages(prams = {}) {
  console.log(prams);
  let token = localStorage.getItem("loginDetails");
  console.log(token);

  let response = await Axios.get(Constant.apiBasePath + "language/get-all", {
    headers: { token: token },
  });

  console.log("response========>");
  console.log(response);

  return response.data;
}
export async function getStoryCategoriesforContest() {
  // console.log(prams);
  let token = localStorage.getItem("loginDetails");
  // console.log(token);

  let response = await Axios.get(
    Constant.apiBasePath + "category/view-all?contestType=1",
    {
      headers: { token: token },
    }
  );

  console.log("response========>");
  console.log(response);

  return response.data;
}

export async function DeleteStory(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  console.log(token);
  let response = await Axios.delete(
    Constant.apiBasePath + `story/user/${prams.id}`,
    {
      headers: { token: token },
      params: prams,
    }
  );

  console.log("response========>");
  console.log(response);

  return response.data;
}

export async function DeletePoem(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  console.log(token);
  let response = await Axios.delete(
    Constant.apiBasePath + `poem/user/${prams.id}`,
    {
      headers: { token: token },
      params: prams,
    }
  );

  console.log("response========>");
  console.log(response);

  return response.data;
}

export async function getBadges(data) {
  console.log("data------------------> response from API");
  console.log(data);
  let token = localStorage.getItem("loginDetails");
  console.log(token);
  let response = await Axios.get(
    Constant.apiBasePath + `badge/getDataById/${data}`,
    {
      headers: { token: token },
    }
  );

  console.log("response========> from API");
  console.log(response);

  return response.data;
}

export async function createStory(data) {
  // data.thumbnail = "https://story-tent-testing.s3.ap-south-1.amazonaws.com/story/story_1722702756513.jpg";
  console.log(data);
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.post(Constant.apiBasePath + "story/create", data, {
    headers: { token: token },
  });
  return response.data;
}

export async function getStoryCategoriesCopy() {
  // console.log(prams);
  let token = localStorage.getItem("loginDetails");
  // console.log(token);

  let response = await Axios.get(
    Constant.apiBasePath + "category/view-all?contestType=0",
    {
      headers: { token: token },
    }
  );

  console.log("response========>");
  console.log(response);

  return response.data;
}

export async function createchat(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.post(
    Constant.apiBasePath + "chat",
    { receiverUserId: data },
    {
      headers: { token: token },
    }
  );
  return response.data;
}

export async function getThumbnailsFromAI(title) {
  console.log("getting from AI");
  const body = {
    steps: 40,
    width: 512,
    height: 512,
    seed: 0,
    cfg_scale: 5,
    samples: 1,
    style_preset: "digital-art",
    text_prompts: [
      {
        // Create a thumbnail for a story titled ${title}. The description is ${desc}
        text: `Create a thumbnail for a story titled ${title}`,
        weight: 1,
      },
      {
        text: "blurry, bad",
        weight: -1,
      },
    ],
  };
  let resp = await Axios.post(
    "https://api.stability.ai/v1/generation/stable-diffusion-v1-6/text-to-image",

    JSON.stringify(body),
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Bearer sk-KMqoTytdE5LJzGmZChOWXAN2oGnKNqmLrdwZ2y7owkUDZ7Ur",
      },
    }
  );
  return [
    `data:image/jpeg;base64,${resp.data.artifacts[0].base64}`,
    resp.data.artifacts[0].base64,
  ];
}

export async function uploadStoryImage(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.post(
    Constant.apiBasePath + "story/uploadFileOnS3",
    data,
    { headers: { token: token } }
  );
  return response.data;
}

function base64ToBlob(base64Data) {
  const byteCharacters = atob(base64Data);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  return new Blob([byteArray], { type: "image/jpeg" });
}

function base64ToBlobCopy(base64Data) {
  // Remove the data URL prefix if present
  const base64 = base64Data.split(",")[1]; // Splits the string and takes only the base64 part

  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: "image/jpeg" });
}

export async function uploadThumbImage(data) {
  try {
    let token = localStorage.getItem("loginDetails");
    let formdata = new FormData();

    const blob = base64ToBlobCopy(data);

    formdata.append("story", blob, "story.jpg");

    let response = await Axios.post(
      Constant.apiBasePath + "story/uploadFileOnS3",
      formdata,
      { headers: { token: token } }
    );

    return response.data;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}

export async function getcomments(id) {
  try {
    let token = localStorage.getItem("loginDetails");
    let response = await Axios.get(Constant.apiBasePath + `getComment/${id}`, {
      headers: { token: token },
    });

    return response.data;
  } catch (error) {
    console.log("internal error", error);
  }
}

export async function getSubcomments(data) {
  try {
    let token = localStorage.getItem("loginDetails");
    let response = await Axios.get(
      Constant.apiBasePath + `getSubComment/${data}`,
      { headers: { token: token } }
    );

    return response.data;
  } catch (error) {
    console.log("internal error", error);
  }
}

export async function getUserportfolioPoem(prams = {}) {
  try {
    let token = localStorage.getItem("loginDetails");
    if (token) {
      let { _id } = getTokenDetails(token);
      prams.user_id = _id;
    } else {
      console.log("unauthorized");
    }
    let response = await Axios.get(
      Constant.apiBasePath + "portfolio/getPortfolioPoem",
      {
        headers: { token: token },
        params: prams,
      }
    );
    console.log("poem response");
    console.log("portfolio", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserportfolioStory(prams = {}) {
  try {
    let token = localStorage.getItem("loginDetails");
    if (token) {
      let { _id } = getTokenDetails(token);
      prams.user_id = _id;
    } else {
      console.log("unauthorized");
    }
    let response = await Axios.get(
      Constant.apiBasePath + "portfolio/getPortfolioStory",
      {
        headers: { token: token },
        params: prams,
      }
    );
    console.log("poem response");
    console.log("portfolio", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getStories(data) {
  let token = localStorage.getItem("loginDetails");

  if (token) {
    let { _id } = getTokenDetails(token);
  }

  console.log("--------------------->", data);
  let url = `${Constant.apiBasePath}story/view-all?pageNo=${
    data.pageNo
  }&limit=10&sort=desc&category=${data.categoryName}&search=${
    data.search || ""
  }`;
  console.log(url);
  let response = await Axios.get(
    `${Constant.apiBasePath}story/view-all?pageNo=${
      data.pageNo || 1
    }&limit=10&sort=desc&category=${data.categoryName || ""}&search=${
      data.search || ""
    }`,
    {
      headers: { token: token },
    }
  );

  return response.data;
}

export async function getFilteredStories(contestId, categoryId) {
  let token = localStorage.getItem("loginDetails");

  if (categoryId === undefined) {
    categoryId = "";
  }
  const url = `${Constant.apiBasePath}story/filter?contestId=${contestId}&categoryId=${categoryId}`;

  try {
    let response = await Axios.get(url, {
      headers: { token: token },
    });

    // Return the data from the response
    return response.data;
  } catch (error) {
    console.error("Error fetching filtered stories:", error);
    throw error;
  }
}
export async function getFilteredPoetries(contestId, categoryId) {
  let token = localStorage.getItem("loginDetails");

  if (categoryId === undefined) {
    categoryId = "";
  }
  const url = `${Constant.apiBasePath}poem/filter?contestId=${contestId}&categoryId=${categoryId}`;

  try {
    let response = await Axios.get(url, {
      headers: { token: token },
    });

    // Return the data from the response
    return response.data;
  } catch (error) {
    console.error("Error fetching filtered stories:", error);
    throw error;
  }
}

export async function getStoriesExplore(data) {
  let token = localStorage.getItem("loginDetails");
  if (token) {
    let { _id } = getTokenDetails(token);
  }

  let response = await Axios.get(Constant.apiBasePath + `story/view-all`, {
    headers: { token: token },
  });

  return response.data;
}

export async function getStoryDetails(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  if (token) {
    let { _id } = getTokenDetails(token);
    prams.user_id = _id;
    prams.userId = _id;
  }
  let response = await Axios.get(Constant.apiBasePath + "story/getDataForApp", {
    headers: { token: token },
    params: prams,
  });
  return response.data;
}

export async function getAnyUserStory(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  // if (token) {
  //   let { _id } = getTokenDetails(token);
  //   prams.user_id = _id;
  //   prams.userId = _id;
  // }
  let response = await Axios.get(Constant.apiBasePath + "story/getDataForApp", {
    headers: { token: token },
    params: prams,
  });
  return response.data;
}

export async function getPoemDetails(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  if (token) {
    let { _id } = getTokenDetails(token);
    prams.user_id = _id;
  } else {
    console.log("unauthorized");
  }
  let response = await Axios.get(Constant.apiBasePath + "poem/getDataForApp", {
    headers: { token: token },
    params: prams,
  });

  console.log("poemdata---->");
  console.log(response);
  return response.data;
}

export async function createPoem(data) {
  let token = localStorage.getItem("loginDetails");
  console.log(data);
  let response = await Axios.post(Constant.apiBasePath + "poem/create", data, {
    headers: { token: token },
  });
  return response.data;
}

export async function getPoems(data) {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.get(
    `${Constant.apiBasePath}poem/view-all?pageNo=${
      data.pageNo || 1
    }&limit=10&sort=desc&category=${data.categoryName || ""}&search=${
      data.search || ""
    }`,
    {
      headers: { token: token },
    }
  );

  console.log(response);
  return response.data;
}

export async function getPoemsEvent(data) {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.get(`${Constant.apiBasePath}poem/view-all`, {
    headers: { token: token },
  });

  console.log(response);
  return response.data;
}

export async function getProfileDetails(params = {}) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(
    Constant.apiBasePath + "user/getProfileDetails",
    {
      headers: { token: token },
      params: params,
    }
  );

  console.log(response);
  return response;
}

export async function getanyUserStories(data) {
  let token = localStorage.getItem("loginDetails");
  if (token) {
    let { _id } = getTokenDetails(token);
  }
  let response = await Axios.get(
    Constant.apiBasePath + `story/getDataForApp?userId=${data.userId}`,
    {
      headers: { token: token },
    }
  );
  return response;
}

export async function getanyUsersPoem(data) {
  let token = localStorage.getItem("loginDetails");
  if (token) {
    let { _id } = getTokenDetails(token);
  }
  let response = await Axios.get(
    Constant.apiBasePath + `poem/getDataForApp?userId=${data.userId}`,
    {
      headers: { token: token },
    }
  );
  return response;
}

export const deleteNotification = async (notificationId) => {
  try {
    const response = await Axios.delete(
      `https://your-api-url/notifications/${notificationId}`
    );
    return response;
  } catch (error) {
    console.error("Error deleting notification:", error);
    throw error;
  }
};

export const getAllLikesCommentsNotification = async (data) => {
  try {
    const response = await Axios.post(
      "https://your-api-url/notifications",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

export async function getAnyProfileDetails(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(
    Constant.apiBasePath + `user/getUserDetails/${data}`,
    {
      headers: { token: token },
    }
  );
  return response;
}

export async function getallChats(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(Constant.apiBasePath + "chat/fetchChats", {
    headers: { token: token },
    params: prams,
  });
  return response;
}

export async function getHomePagePosts(page) {
  let token = localStorage.getItem("loginDetails");

  console.log(token);
  let { _id } = getTokenDetails(token);
  let userId = _id;
  console.log("userId: ", userId);
  let response = await Axios.post(
    Constant.apiBasePath + `home/page/userId?pageNo=${page}&limit=10`,
    { params: userId },
    {
      headers: {
        token: token,
      },
    }
  );
  console.log("Data: ", response.data);
  return response.data;
}

export async function getHomePagePostsAdditional(page) {
  console.log("additnal called");

  let token = localStorage.getItem("loginDetails");
  let { _id } = getTokenDetails(token);
  let userId = _id;
  let response = await Axios.get(
    Constant.apiBasePath + `home/page/additional?pageNo=${page}&limit=10`,
    { params: userId },
    {
      headers: {
        token: token,
      },
    }
  );
  return response.data;
}

export async function getallMessages(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(Constant.apiBasePath + `message/${data}`, {
    headers: { token: token },
  });
  return response;
}

export async function userCoin(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.post(Constant.apiBasePath + "userCoin", data, {
    headers: { token: token },
  });
  return response.data;
}

export async function getSiteSocial(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.post(Constant.apiBasePath + "getSocialMediaData", {
    headers: { token: token },
    params: prams,
  });
  return response.data;
}

export async function getPages(prams = {}) {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.post(
    Constant.apiBasePath + "searchChildCMSByTitle",
    prams,
    {
      headers: { token: token },
    }
  );
  return response.data;
}

export async function getUserByName(search) {
  // console.log(params);
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.get(
    Constant.apiBasePath + `user/searchUsersByName?name=${search}`,

    { headers: { token: token } }
  );
  return response;
}

export async function getVideos(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  if (token) {
    let { _id } = getTokenDetails(token);
    prams.user_id = _id;
  }
  let response = await Axios.get(
    Constant.apiBasePath + "video/getDataForUser",
    {
      headers: { token: token },
      params: prams,
    }
  );
  return response.data;
}

export async function getMovies(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  if (token) {
    let { _id } = getTokenDetails(token);
    prams.user_id = _id;
  }
  let response = await Axios.get(
    Constant.apiBasePath + "movies/getDataForUser",
    {
      headers: { token: token },
      params: prams,
    }
  );
  return response.data;
}

export async function getAudios(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  if (token) {
    let { _id } = getTokenDetails(token);
    prams.user_id = _id;
  }
  let response = await Axios.get(
    Constant.apiBasePath + "audio/getDataForUser",
    {
      headers: { token: token },
      params: prams,
    }
  );
  return response.data;
}

export async function userLike(data) {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.post(Constant.apiBasePath + "like", data, {
    headers: { token: token },
  });
  return response.data;
}

// export async function userLikeFunction(data) {
//   let token = localStorage.getItem("loginDetails");
//   let response = await Axios.post(Constant.apiBasePath + "like", data, {
//     headers: { token: token },
//   });
//   return response.data;
// }

export async function getNotification(prams = {}) {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.get(
    Constant.apiBasePath + "notification/getByUserId",
    {
      headers: { token: token },
      params: prams,
    }
  );
  return response.data;
}

export const getNotificationForUser = async (params) => {
  let token = localStorage.getItem("loginDetails");
  console.log(token);

  try {
    let response = await Axios.post(
      Constant.apiBasePath + "notification/getAllLikesCommentsNotification",
      params,
      {
        headers: {
          token: token,
        },
      }
    );
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

export const getUnreadNotificationCount = async (params) => {
  let token = localStorage.getItem("loginDetails");
  try {
    let res = await Axios.post(
      Constant.apiBasePath + "notification/getUnreadNotificationCount",
      params,
      {
        headers: { token: token },
      }
    );
    console.log(res.data.data);
    return res.data.data;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

export const getUnreadMessageCount = async () => {
  let token = localStorage.getItem("loginDetails");
  try {
    let res = await Axios.get(Constant.apiBasePath + "message/unreadCount", {
      headers: { token: token },
    });
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error("Error fetching message count:", error);
    throw error;
  }
};
export const searchUserByName = async (name) => {
  try {
    const response = await Axios.get(
      `https://api.storytent.in/api/user/searchUsersByName?name=${name}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching user details for name ${name}:`, error);
    throw error;
  }
};
export async function getThumbnail(prams = {}) {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.get(
    Constant.apiBasePath + "content-thumbnail/getDataForUser",
    {
      headers: { token: token },
      params: prams,
    }
  );
  return response.data;
}

export async function deleteStoryOrPoem(prams = {}) {
  let token = localStorage.getItem("loginDetails");

  let type = "";
  if (prams.type === 1) {
    type = "story";
  } else if (prams.type === 2) {
    type = "poem";
  }

  let response = await Axios.delete(
    Constant.apiBasePath + type + "/user/" + prams.id,
    {
      headers: { token: token },
      params: prams,
    }
  );
  return response.data;
}

export async function getDataById(prams = {}) {
  let token = localStorage.getItem("loginDetails");

  let type = "";
  if (prams.type == 1) {
    type = "story";
  } else if (prams.type == 2) {
    type = "poem";
  }

  let response = await Axios.get(
    Constant.apiBasePath + type + "/getDataById/" + prams.id,
    {
      headers: { token: token },
      params: prams,
    }
  );
  return response.data;
}
export async function getContestDataById(prams = {}) {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.get(
    Constant.apiBasePath + "contest/getDataById/" + prams.id,
    {
      headers: { token: token },
      params: prams,
    }
  );
  return response.data;
}

export async function getFollowOrFollowingList(metaData) {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.post(
    Constant.apiBasePath + "getFollowOrFollowingList",
    metaData,
    { headers: { token: token } }
  );
  return response.data;
}

export async function getUserBadge(params) {
  let token = localStorage.getItem("loginDetails");
  let metaData = {};
  let response = await Axios.post(
    Constant.apiBasePath + "getUserBadge?type=" + params.type,
    metaData,
    { headers: { token: token } }
  );
  return response.data;
}

export async function getTickets(params = {}) {
  let token = localStorage.getItem("loginDetails");
  if (token) {
    let { _id } = getTokenDetails(token);
    params.user_id = _id;
  }
  let response = await Axios.get(
    Constant.apiBasePath + "event/tickets/" + params.user_id,
    {
      headers: { token: token },
      params: params,
    }
  );
  return response.data;
}

export async function userFollow(data) {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.post(
    Constant.apiBasePath + "follow-user",
    { followingId: data.followingId, status: data.status },
    {
      headers: { token: token },
    }
  );
  return response.data;
}

export async function getbannerResult() {
  let token = localStorage.getItem("loginDetails");

  let response = await Axios.get(
    Constant.apiBasePath + "HomepageBanner/get-all",
    {
      headers: { token: token },
    }
  );
  console.log("result contest homepage ", response.data);
  return response.data;
}

export async function getContestResultList(prams = {}) {
  let token = localStorage.getItem("loginDetails");
  console.log(prams);

  let response = await Axios.get(
    Constant.apiBasePath +
      "contest/getContestResultById/" +
      prams.id +
      "?type=" +
      prams.type,
    {
      headers: { token: token },
    }
  );
  console.log("result ", response.data);
  return response.data;
}

export async function createPaymentRequest(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.post(
    Constant.apiBasePath + "order/createPaymentRequest",
    data,
    { headers: { token: token } }
  );
  return response.data;
}

export async function updatePaymentDetails(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.post(
    Constant.apiBasePath + "order/updatePaymentDetails",
    data,
    { headers: { token: token } }
  );
  return response.data;
}

export async function createAppPaymentRequest(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(
    Constant.apiBasePath + "order/createPaymentRequestForApp",
    {
      headers: { token: token },
      params: data,
    }
  );
  return response.data;
}

export async function updateAppPaymentDetails(data) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(
    Constant.apiBasePath + "order/updatePaymentDetailsForApp",
    {
      headers: { token: token },
      params: data,
    }
  );
  return response.data;
}

export async function getAllContestData(fromPoem, body) {
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZDM3NDA0Nzk0MDRhOTY1Mzk0OWJlOSIsIm5hbWUiOiJSYXZpbmRyYSBZYWRhdiIsImVtYWlsIjoiUmF2aW5kcmF5YWRhdjU0MzhAZ21haWwuY29tIiwicHJvZmlsZVBpYyI6IiIsInN0YXR1cyI6MCwicm9sZSI6MSwiaWF0IjoxNzA0NjA5NTU4LCJleHAiOjE3MjAxNjE1NTh9.v2dM4RsOymcsHEtBoyHIDS-GTYCJ6gn1Ws06OshPc1s";
  if (fromPoem) {
    const endUrl = Constant.apiBasePath + "story/get-all";
    try {
      let metaInfo = await Axios.post(endUrl, body, {
        headers: { token: token },
      });
      return metaInfo;
    } catch (error) {
      alert("Error", "Server side issue");
      return error;
    }
  } else {
    const endUrl = Constant.apiBasePath + "poem/get-all";
    try {
      let metaInfo = await Axios.post(endUrl, body, {
        headers: { token: token },
      });
      return metaInfo;
    } catch (error) {
      alert("Error", "Server side issue");
      return error;
    }
  }
}

export async function getOpenMicContests(data) {
  let token = localStorage.getItem("loginDetails");
  try {
    const response = await Axios.post(
      Constant.apiBasePath + "openMic/get-all",
      data,
      {
        headers: { token: token },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching open mic contests:", error);
    throw error; // You might want to handle this error in the component that calls this function
  }
}

export async function getOpenMicEvents(params = {}) {
  try {
    // Retrieve the token from localStorage if needed
    let token = localStorage.getItem("loginDetails");

    // Make the API call
    let response = await Axios.get(`${Constant.apiBasePath}open-mic/get-all`, {
      headers: { token: token },
      params: params,
    });

    // Check for successful response
    if (response.data.success) {
      return { status: "Success", data: response.data.data };
    } else {
      return { status: "Error", message: "Failed to fetch open mic events" };
    }
  } catch (error) {
    console.error("Error fetching open mic events:", error);
    return { status: "Error", message: error.message };
  }
}
export async function getAllContests(data) {
  let token = localStorage.getItem("loginDetails");
  try {
    const response = await Axios.post(
      Constant.apiBasePath + "contest/get-all",
      data,
      {
        headers: { token: token },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching open mic contests:", error);
    throw error;
  }
}

export async function getOpenMicContestsById(id) {
  let token = localStorage.getItem("loginDetails");
  try {
    const response = await Axios.post(
      `${Constant.apiBasePath}openMic/get-all/${id}`,
      null,
      {
        headers: { token: token },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching open mic contests:", error);
    throw error;
  }
}

export async function getOpenMicDataById2(id) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(
    `${Constant.apiBasePath}openMic/getDataById/${id}`,
    {
      headers: { token: token },
    }
  );
  console.log(response.data);
  return response.data;
}

export async function getParticipantsById(id) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.get(
    `${Constant.apiBasePath}openMic/getParticipantsById/${id}`,
    {
      headers: { token: token },
    }
  );
  console.log(response.data);
  return response.data;
}
export async function likeParticipant(id) {
  let token = localStorage.getItem("loginDetails");
  let response = await Axios.patch(
    `${Constant.apiBasePath}openMic/addLike/${id}`,
    {},
    {
      headers: { token: token },
    }
  );
  console.log(response.data);
  return response.data;
}

export async function createOfflineOpenMicParticipant(data) {
  let token = localStorage.getItem("loginDetails");
  try {
    const response = await Axios.post(
      `${Constant.apiBasePath}offlineOpenMic/participants/create`,
      data, // Data sent as JSON in the request body
      {
        headers: {
          token: token,
          "Content-Type": "application/json", // Ensure content type is JSON
        },
      }
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating offline open mic participant:", error);
    throw error;
  }
}

export async function getOfflineOpenMics() {
  const token = localStorage.getItem("loginDetails");

  try {
    const response = await Axios.get(
      `${Constant.apiBasePath}offlineOpenMic/get-all`,
      {
        headers: {
          token: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching offline open mic events:", error);
    throw error;
  }
}
export async function createGroupChat(data) {
  const token = localStorage.getItem("loginDetails");

  try {
    const response = await Axios.post(
      `${Constant.apiBasePath}chat/group`,
      data,
      {
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Group chat", response.data);
    return response.data;
  } catch (error) {
    console.error("Unable to create group chat", error);
    throw error;
  }
}

export async function getAllUsers() {
  const token = localStorage.getItem("loginDetails");
  try {
    const response = await Axios.post(
      `${Constant.apiBasePath}user/get-all-once`,
      {},
      {
        headers: {
          token: token,
        },
      }
    );
    console.log("All the users are", response);
    return response;
  } catch (error) {
    console.error("Unable to fetch all users", error);
    throw error;
  }
}
export async function getSpotlight() {
  const token = localStorage.getItem("loginDetails");
  try {
    const response = await Axios.get(
      `${Constant.apiBasePath}spotlight/author/get-all`,
      {
        headers: {
          token: token,
        },
      }
    );
    console.log("All the users are", response);
    return response;
  } catch (error) {
    console.error("Unable to fetch all users", error);
    throw error;
  }
}

export async function deleteComment(data) {
  let token = localStorage.getItem("loginDetails");
  console.log(token);

  let response = await Axios.delete(
    `${Constant.apiBasePath}deleteComment/${data}`,
    {
      headers: { token: token },
    }
  );

  console.log("DeleteComment api call", response);

  return response;
}

export async function deleteSubComment(data) {
  let token = localStorage.getItem("loginDetails");
  console.log(token);

  let response = await Axios.delete(
    `${Constant.apiBasePath}deleteSubComment/${data}`,
    {
      headers: { token: token },
    }
  );

  console.log("DeleteComment api call", response);

  return response;
}

export async function getRegistrations() {
  let token = localStorage.getItem("loginDetails");
  try {
    let response = await Axios.get(
      `https://api.storytent.in/api/registration-forms/get-all`
    );

    console.log("All the users are", response);
    return response;
  } catch (error) {
    console.error("Unable to fetch all users", error);
    throw error;
  }
}
export async function getEventsQuestions(eventId) {
  let token = localStorage.getItem("loginDetails");
  try {
    let response = await Axios.get(
      `${Constant.apiBasePath}registration-forms/${eventId}`,
      {
        headers: { token: token },
      }
    );

    console.log("All the users are", response);
    return response;
  } catch (error) {
    console.error("Unable to fetch all users", error);
    throw error;
  }
}
export async function submitUserResponses(data) {
  let token = localStorage.getItem("loginDetails");
  console.log(data);
  try {
    let response = await Axios.post(
      `${Constant.apiBasePath}user-responses`,
      data
    );

    console.log("submitted", response);
    return response;
  } catch (error) {
    console.error("error ", error);
    throw error;
  }
}

export async function getAllMeetings() {
  const token = localStorage.getItem("loginDetails");
  try {
    const response = await Axios.get(`${Constant.apiBasePath}meeting/get-all`, {
      headers: {
        token: `${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("error ", error);
    throw error;
  }
}
