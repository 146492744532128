// import React, { useState } from "react";
// import "../../src/css/Poll.css";

// const Poll = () => {
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [likes, setLikes] = useState(Array.from({ length: 32 }, () => 0));
//   const [userSelections, setUserSelections] = useState(Array.from({ length: 32 }, () => false));

//   const handleOptionSelect = (userName, index) => {
//     if (!userSelections[index]) {
//       setSelectedOption(userName);
//       const updatedLikes = [...likes];
//       updatedLikes[index] += 1;
//       setLikes(updatedLikes);
//       const updatedUserSelections = [...userSelections];
//       updatedUserSelections[index] = true;
//       setUserSelections(updatedUserSelections);
//     }
//   };

//   const userNames = Array.from({ length: 32 }, (_, index) => `user${index + 1}`);

//   return (
//     <div className="poll-container">
//       <h2 className="poll-question">Poll Likes</h2>
//       <div className="poll-options">
//         {userNames.map((userName, index) => (
//           <div key={index} className={`poll-option ${selectedOption === userName ? "selected" : ""}`} onClick={() => handleOptionSelect(userName, index)}>
//             {userName}
//             <span className="like-icon" onClick={(e) => {
//               e.stopPropagation();
//               handleOptionSelect(userName, index);
//             }}> &#x2764; {likes[index]}</span>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Poll;

import React, { useEffect, useState } from "react";
import "../../src/css/Poll.css";
import { getParticipantsById, likeParticipant } from "./utilities/ApiCalls";
import { getTokenDetails } from "./utilities/CommonFunction";

const Poll = ({ openMicData }) => {
  const [participants, setParticipants] = useState([]);
  const [myId, setMyId] = useState("");
  const [likedParticipants, setLikedParticipants] = useState({});

  // Fetch participants and user ID on mount
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (openMicData?._id) {
          const response = await getParticipantsById(openMicData._id);
          setParticipants(response.data);

          // Fetch the current user's ID from token
          const token = localStorage.getItem("loginDetails");
          if (token) {
            const { _id } = getTokenDetails(token);
            setMyId(_id);

            // Initialize liked state based on whether the user has liked each participant
            const initialLikedState = response.data.reduce((acc, participant) => {
              acc[participant._id] = participant.likes.some((like) => like.userId === _id);
              return acc;
            }, {});
            setLikedParticipants(initialLikedState);
          }
        }
      } catch (error) {
        console.error("Error fetching open mic data:", error);
      }
    };

    fetchInitialData();
  }, [openMicData?._id]);

  const handleOptionSelect = async (participant) => {
    try {
      const isLiked = likedParticipants[participant._id];

      // Call API to like or unlike
      await likeParticipant(participant?._id);

      // Update local state for likedParticipants
      setLikedParticipants((prev) => ({
        ...prev,
        [participant._id]: !isLiked,
      }));

      // Update local state for participants' likes count
      setParticipants((prev) =>
        prev.map((item) =>
          item._id === participant._id
            ? {
                ...item,
                likes: isLiked
                  ? item.likes.filter((like) => like.userId !== myId) // Remove like
                  : [...item.likes, { userId: myId, createdAt: new Date().toISOString() }], // Add like
              }
            : item
        )
      );
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  return (
    <div className="poll-container">
      <h2 className="poll-question">Poll Likes</h2>
      <div className="poll-options">
        {participants.map((participant) => (
          <div
            key={participant._id}
            className="poll-option"
            onClick={() => handleOptionSelect(participant)}
          >
            {participant.name}
            <span
              className="like-icon"
              style={{
                color: likedParticipants[participant._id] ? "red" : "white",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleOptionSelect(participant);
              }}
            >
              &#x2764; {participant.likes.length}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Poll;
