import axios from "axios";
import React, { useEffect, useState } from "react";
import ZoomMeeting from "./ZoomMeeting";
import { useLocation } from "react-router-dom";
import Constant from "../Constant";
import { getTokenDetails } from "../utilities/CommonFunction";
import { getAnyProfileDetails } from "../utilities/ApiCalls";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
  },
  // ... rest of your styles
};

const ZoomHome = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [meetingPayload, setMeetingPayload] = useState(null);

  const location = useLocation();

  useEffect(() => {
    // Get the payload from router state
    const configPayload = location.state?.meetingConfig;
    console.log("meetiing data ", configPayload);

    if (configPayload) {
      setMeetingPayload(configPayload);
    }
  }, [location]);

  useEffect(() => {
    const fetchUser = async () => {
      let token = localStorage.getItem("loginDetails");
      const { _id } = getTokenDetails(token);
      try {
        const res = await getAnyProfileDetails(_id);

        meetingPayload.userName = res.data.data.name;
        meetingPayload.userEmail = res.data.data.email;
        meetingPayload.leaveUrl = "https://storytent.in/";

        console.log(meetingPayload);
      } catch (error) {
        console.error("Error fetching user token:", error);
        setError("Failed to fetch user details");
      }
    };

    if (meetingPayload) {
      fetchUser();
    }
  }, [meetingPayload]);

  if (!meetingPayload) {
    return (
      <div style={styles.loadingText}>Loading meeting configuration...</div>
    );
  }

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  return (
    <div style={styles.container}>
      <ZoomMeeting payload={meetingPayload} />
    </div>
  );
};

export default ZoomHome;

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import ZoomMeeting from "./ZoomMeeting";
// import { useLocation } from "react-router-dom";
// import Constant from "../Constant";

// const styles = {
//   container: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     minHeight: "100vh",
//     backgroundColor: "#f5f5f5",
//   },
//   card: {
//     backgroundColor: "white",
//     borderRadius: "8px",
//     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//     padding: "32px",
//     width: "400px",
//   },
//   error: {
//     backgroundColor: "#fde8e8",
//     color: "#dc2626",
//     padding: "16px",
//     borderRadius: "4px",
//     marginBottom: "16px",
//   },
//   button: {
//     width: "100%",
//     padding: "10px 24px",
//     backgroundColor: "black",
//     color: "white",
//     border: "2px solid #3b82f6",
//     borderRadius: "4px",
//     cursor: "pointer",
//     fontSize: "16px",
//     transition: "background-color 0.2s",
//   },
//   buttonHover: {
//     backgroundColor: "#333333",
//   },
//   loadingText: {
//     textAlign: "center",
//     padding: "10px 0",
//   },
// };

// const ZoomHome = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [isHovered, setIsHovered] = useState(false);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const location = useLocation();

//   useEffect(() => {
//       // Hide Zoom root on component mount
//       const zoomRoot = document.getElementById("zmmtg-root");
//       if (zoomRoot) {
//           zoomRoot.style.display = "hidden";
//       }

//       // Check for auth code in URL
//       const urlParams = new URLSearchParams(location.search);
//       const code = urlParams.get('code');

//       if (code) {
//           handleZoomCallback(code);
//       }
//   }, [location]);

//   const handleAuth = async() => {
//       // const res = await axios.get("http://localhost:8083/api/zoom/auth");
//       // const url = res.data.url;
//       const url = "https://zoom.us/oauth/authorize?client_id=3PCfVxcLSZiDuwGHV_l1qA&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2FStorytent-Meeting";
//       window.location.href = url;
//   };

//   const handleZoomCallback = async (code) => {
//       setLoading(true);
//       setError(null);

//       try {
//           const response = await axios.get(
//               `http://localhost:8083/api/zoom/callback?code=${code}`,
//               {
//                   headers: {
//                       'Authorization': `${localStorage.getItem("loginDetails")}`
//                   }
//               }
//           );

//           const { access_token, refresh_token, expires_in } = response.data.data;

//           // Store tokens
//           localStorage.setItem('zoom_access_token', access_token);
//           if (refresh_token) {
//               localStorage.setItem('zoom_refresh_token', refresh_token);
//           }

//           // Store expiration time
//           const expirationTime = new Date().getTime() + expires_in * 1000;
//           localStorage.setItem('zoom_token_expiry', expirationTime);

//           // GET THE ZAK TOKEN USING THIS ACCES TOKEN
//           const zakResponse = await axios.get('http://localhost:8083/api/zoom/zak', {
//             headers: {
//               'Authorization': access_token
//             }
//           });

//           const zakToken = zakResponse.data.token;

//           // Update payload with ZAK token
//           payload.zakToken = zakToken;

//           setIsAuthenticated(true);
//       } catch (error) {
//           console.error('Token exchange failed:', error);
//           setError('Failed to authenticate with Zoom. Please try again.');
//           setIsAuthenticated(false);
//       } finally {
//           setLoading(false);
//       }
//   };

//   // Check if access token exists and is not expired
//   const checkAuth = () => {
//     const accessToken = localStorage.getItem("zoom_access_token");
//     const tokenExpiry = localStorage.getItem("zoom_token_expiry");

//     if (accessToken && tokenExpiry) {
//       const isExpired = new Date().getTime() > parseInt(tokenExpiry);
//       return !isExpired;
//     }
//     return false;
//   };
//   const payload = {
//     authEndpoint: "http://localhost:4000", // http://localhost:4000
//     sdkKey: "3PCfVxcLSZiDuwGHV_l1qA",
//     meetingNumber: 86566979230,
//     passWord: "z3FRxk",
//     role: 0,
//     userName: "Sameer",
//     userEmail: "Sameer@gmail.com",
//     registrantToken: "",
//     zakToken: "",
//     leaveUrl: "http://localhost:3001/",
//   };

//   return (
//     <div style={styles.container}>
//       {/* <ZoomMeeting payload={payload} /> */}
//       {loading ? (
//         <div style={styles.loadingText}>Authenticating with Zoom...</div>
//       ) : error ? (
//         <div style={styles.card}>
//           <div style={styles.error}>{error}</div>
//           <button
//             onClick={handleAuth}
//             style={{
//               ...styles.button,
//               ...(isHovered ? styles.buttonHover : {}),
//             }}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//           >
//             Try Again
//           </button>
//         </div>
//       ) : isAuthenticated || checkAuth() ? (
//         <ZoomMeeting payload={payload} />
//       ) : (
//         <div style={styles.card}>
//           <button
//             onClick={handleAuth}
//             style={{
//               ...styles.button,
//               ...(isHovered ? styles.buttonHover : {}),
//             }}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//           >
//             Sign in with Zoom
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ZoomHome;
