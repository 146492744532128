import { Toaster, toast } from "sonner";
import { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import Context from "../../Context";
import { LuSend } from "react-icons/lu";
import narattorImg from "../../Images/narattorImg.png";
import moment from "moment";
import Layout from "../../Layout/Layout";
import { Helmet } from "react-helmet-async";

import styled from "styled-components";
// import "../../css/Stories.css";
// import "../../css/StoryDetailsNew.css"
import Constant, { statusFailure } from "../../views/Constant";
import {
  DeleteStory,
  getProfileDetails,
  getStories,
  getStoryCategories,
  getStoryDetails,
  getSubcomments,
  getUserportfolioStory,
  getcomments,
  userFollow,
  userLike,
  getDataById,
  deleteComment,
  deleteSubComment,
} from "../utilities/ApiCalls";
import {
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaVolumeUp,
} from "react-icons/fa";
import { getTokenDetails } from "../utilities/CommonFunction";
import { FaRegCommentAlt } from "react-icons/fa";
import {
  CommentCart,
  CommentContainer,
  CommentWritingSection,
  Headerforcomment,
  ReplyInput,
  ScrollableDiv,
} from "./StoryDetailStyle";
import {
  Clock,
  Clock10Icon,
  Columns,
  Eye,
  EyeIcon,
  GripVertical,
  HeartIcon,
  Link2,
  MessageCircle,
  MessageCircleCodeIcon,
  Plus,
  Scroll,
  Send,
  SendHorizonalIcon,
  SendIcon,
  Settings,
  Settings2,
  Settings2Icon,
  TheaterIcon,
  ThumbsUp,
  Trash,
  UserPlus,
} from "lucide-react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import Default from "./../../Images/Defaultprofile.png";
import Heart from "../../Images/heart.png";
import { useModel } from "../../hooks/modelcontex";

function StroyDetailsNew(props) {
  let { id } = useParams();
  const [commentLike, setcommentLike] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [story, setStory] = useState({});
  const [viewAll, setViewAll] = useState();
  const [userProfileImage, setUserProfileImage] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [ReplyOpen, setReplyOpen] = useState();
  const [pageNo, setPageNo] = useState(1);
  const location = useLocation();
  const [commentopen, setcommentopen] = useState(false);
  const [isLike, setLike] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorState, setErrorState] = useState(null);
  const [similarStory, setSimilarStory] = useState([]);
  const history = useHistory();
  const { state, dispatch } = useContext(Context);
  const [similarId, setSimilarId] = useState("");
  const [userId, setUserId] = useState("");
  const [userBadge, setUserBadge] = useState("");
  const [isAccess, setIsAccess] = useState(true);
  const [profile, setProfile] = useState();
  const [Error, setError] = useState();
  const [isFollow, setFollow] = useState(false);
  const [subComment, setsubComment] = useState("");
  const [usercomments, setusercomments] = useState({});
  const [issubcommentposted, setissubcommentposted] = useState(false);
  const [isposted, setisposted] = useState(false);
  const [userSubcomments, setSubusercomments] = useState({});
  const [replyopen, setreplyopen] = useState("");
  const [comment, setcomment] = useState("");
  const [duration, setDuration] = useState();
  const [isInport, setIsinPort] = useState(false);
  const [badges, setBadges] = useState([]);
  const [category, setcategory] = useState("");
  const [commentlike, setcommentlike] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteMenu, setOpenDeleteMenu] = useState(false);
  const { Myprofile, myprofile } = useModel();
  const [activeMenu, setActiveMenu] = useState(null);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [present, setPresent] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // const [text, setText] = useState('');
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [rate, setRate] = useState(1);
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [speech, setSpeech] = useState(false);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const loadVoices = () => {
      setVoices(synth.getVoices());
    };

    if (synth.onvoiceschanged !== undefined) {
      synth.onvoiceschanged = loadVoices;
    }
    loadVoices();
  }, []);

  // const handleChange = (event) => {
  //     setText(event.target.value);
  // };

  const handleVoiceChange = (event) => {
    setSelectedVoice(event.target.value);
    handleStop();
    setSpeech(false);
  };

  const handleRateChange = (event) => {
    setRate(event.target.value);
    handleStop();
    setSpeech(false);
  };

  const handleSpeak = (text) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      if (selectedVoice) {
        utterance.voice = voices.find((voice) => voice.name === selectedVoice);
      }
      utterance.rate = rate;

      utterance.onstart = () => {
        setMessage("Speech has started.");
        setProgress(0);
      };

      utterance.onend = () => {
        setMessage("Speech has finished.");
        setProgress(100);
      };

      utterance.onerror = (event) => {
        setMessage(`An error occurred: ${event.error}`);
        setProgress(0);
      };

      utterance.onboundary = (event) => {
        if (event.name === "word") {
          // Estimate progress based on the word index
          // const words = text.split(' ').length;
          const progressValue = (event.charIndex / text.length) * 100;
          setProgress(progressValue);
        }
      };

      window.speechSynthesis.speak(utterance);
    } else {
      alert("Sorry, your browser does not support text-to-speech.");
    }
  };

  const handleStop = () => {
    if ("speechSynthesis" in window) {
      window.speechSynthesis.cancel();
      setMessage("Speech has been stopped.");
      setProgress(0);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [portchange, setPortChange] = useState(false);
  const { t } = useTranslation();

  const [copySuccess, setCopySuccess] = useState(false);
  const [sharelink, setShareLink] = useState(false);
  const [link, setlink] = useState("");

  const divRef = useRef(null);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setShareLink(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  async function portfolioStroyfetch() {
    try {
      const response = await getUserportfolioStory();
      console.log("name");
      console.log(response);
      await Promise.all(
        response.data.map(async (data) => {
          if (story._id == data.eventId) {
            setIsinPort(true);
          } else {
            setIsinPort(false);
          }
        })
      );
      if (response.data.length == 0) {
        setIsinPort(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    portfolioStroyfetch();
  }, [story, portchange]);

  // Function to copy text to clipboard

  const copyToClipboard = async (text) => {
    navigator.clipboard.writeText(text);
    console.log(text);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };
  const contentRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function fetchMoreData() {
    console.log("fetchMoreData");
    let nextPage = pageNo + 1;
    setPageNo(nextPage);
  }

  async function RemoveFromPortfolio(data) {
    try {
      console.log(data);
      let token = localStorage.getItem("loginDetails");
      let response = await axios.delete(
        Constant.apiBasePath + `portfolio/removeEvent/${data}`,
        { headers: { token: token } }
      );
      console.log("saved data=======>");
      console.log(response);
      setPortChange(!portchange);
      return response.data;
    } catch (error) {
      console.log("internal server error", error);
    }
  }

  async function addtoPortfolio(data) {
    try {
      console.log(data);
      let token = localStorage.getItem("loginDetails");
      let response = await axios.post(
        Constant.apiBasePath + `portfolio/${data}`,
        { description: " " },
        { headers: { token: token } }
      );
      console.log("saved data=======>");
      console.log(response);
      setPortChange(!portchange);
      return response.data;
    } catch (error) {
      console.log("internal server error", error);
    }
  }

  const SubmitComment = async (e) => {
    e.preventDefault();
    try {
      console.log("comment", comment);
      let token = localStorage.getItem("loginDetails");

      let response = await axios.post(
        Constant.apiBasePath + `comment/${id}`,
        { description: comment },
        { headers: { token: token } }
      );
      console.log("comment response", response);
      setcomment("");
      // console.log([...usercomments,response.data.data])
      // setusercomments((curr)=>[...curr,response.data.data]);
      setisposted((curr) => !curr);
      return response.data;
    } catch (error) {
      console.log("internal server error", error);
    }
  };

  const SubmitSubComment = async (commentId) => {
    try {
      console.log("subComment", subComment);
      let token = localStorage.getItem("loginDetails");

      let response = await axios.post(
        Constant.apiBasePath + `subComment/${commentId}`,
        { description: subComment },
        { headers: { token: token } }
      );
      console.log("saved data subcomment=======>");
      console.log("SubComment response", response);
      setsubComment("");
      setissubcommentposted(!issubcommentposted);
      setReplyOpen(false);
      return response.data;
    } catch (error) {
      console.log("internal server error", error);
    }
  };

  async function CommentLike(data) {
    try {
      let token = localStorage.getItem("loginDetails");
      console.log(token);
      let response = await axios.patch(
        Constant.apiBasePath + `addRemoveLike/${data}`,
        {},
        { headers: { token: token } }
      );
      console.log("commentlike-------->");
      setcommentLike(!commentLike);

      return response.data;
    } catch (error) {
      console.log("internal error", error);
    }
  }

  async function CommentDelete(commentId) {
    try {
      let response = await deleteComment(commentId);
      console.log("delete comment response", response);
      if (response.status === 200) {
        console.log("Comment successfully deleted");

        setisposted((curr) => !curr);
      } else {
        console.error("Failed to delete comment:", response.message);
      }
    } catch (error) {
      console.error("Error occurred while deleting comment:", error);
    }
  }
  async function SubCommentDelete(commentId) {
    try {
      let response = await deleteSubComment(commentId);
      console.log("delete comment response", response);
      if (response.status === 200) {
        console.log("Comment successfully deleted");

        setisposted((curr) => !curr);
      } else {
        console.error("Failed to delete comment:", response.message);
      }
    } catch (error) {
      console.error("Error occurred while deleting comment:", error);
    }
  }
  const toggleMenu = (commentId) => {
    setActiveMenu((prev) => (prev === commentId ? null : commentId));
  };

  useEffect(() => {
    console.log(story._id);
    console.log();
    Usercomments(story._id);
  }, [story, isposted, issubcommentposted, commentLike]);

  async function Usercomments(id) {
    try {
      let usercommentsArray = [];
      const commentsResponse = await getcomments(id); // Assuming getcomments function returns a promise
      const commentsData = commentsResponse.data;

      // Iterate over comments and fetch their subcomments
      await Promise.all(
        commentsData.map(async (item) => {
          const subcommentsResponse = await getSubcomments(item._id); // Assuming getSubcomments function returns a promise
          const subcommentsData = subcommentsResponse.data;

          // Push comment and its subcomments to usercommentsArray
          usercommentsArray.push({
            usercomment: item,
            usersubcomments: subcommentsData,
          });
        })
      );
      usercommentsArray.sort((a, b) => {
        return (
          new Date(a.usercomment.createdAt) - new Date(b.usercomment.createdAt)
        );
      });
      // Update state with structured array
      console.log("usercommentsArray", usercommentsArray);
      setusercomments(usercommentsArray);
    } catch (error) {
      console.error("Error fetching comments and subcomments:", error);
      // Handle error appropriately
    }
  }

  const Category = () => {
    getStoryCategories().then((response) => {
      console.log(response);
      response.data.map((data) => {
        if (data._id == story.categoryId) {
          setcategory(data.name);
        }
      });
    });
  };

  useEffect(() => {
    Category();
  }, [story]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        setcommentopen(false);
        console.log("Clicked outside. Closing...");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getProfileDetails().then((response) => {
      if (response.status !== statusFailure) {
        setProfile(response.data.data);
      } else {
        setError(response.message);
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(id);
        let prams = { id: id };
        let token = localStorage.getItem("loginDetails");

        if (similarId) {
          prams = { id: similarId };
        } else {
          console.log("similar id not found");
        }

        if (token) {
          let userDetails = getTokenDetails(token);
          if (userDetails) {
            prams.user_id = userDetails._id;
            setUserId(userDetails._id);
          }
        } else {
          console.log("user not found");
        }

        if (state.user.userBadge) {
          setUserBadge(state.user.userBadge.title);
        }

        const response = await getStoryDetails(prams);
        if (response.status !== statusFailure) {
          setStory(response.data[0]);
          setSimilarStory(response.similarStory);
          setIsAccess(response.isAccess);

          if (response.isAccess === false || response.isBadge === true) {
            setSuccessMessage(response.message);
          }
        } else {
          setErrorState(response.message);
          setTimeout(() => {
            setErrorState("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error fetching story details:", error);
        history.push("/");
        setErrorState("An error occurred while fetching the story details.");
        setTimeout(() => {
          setErrorState("");
        }, 3000);
      }
    };

    fetchData();
  }, [isLike, similarId, isFollow, id]);

  const Duration = (createdAt) => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const currentDate = Date.now();
    const differenceInMilliseconds =
      currentDate - new Date(createdAt).getTime();
    const days = Math.floor(differenceInMilliseconds / millisecondsPerDay);
    return days;
  };

  function like() {
    let likeRequest = {
      moduleId: story._id,
      type: 1,
      user_id: "",
      title: story.name,
      description: story.description,
      thumbnail: story.thumbnail,
    };

    userLike(likeRequest).then((response) => {
      if (response.status !== statusFailure) {
        setLike(response.data.userLike);
      }
    });
  }

  function extractDate(createdAt) {
    const date = new Date(createdAt);

    // Extracting date components
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = date.getDate();

    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
  }

  function verifyUser(id, standard) {
    setSimilarId(id);
    if (standard === 2) {
      if (state.user.isPrime) {
        history.push("/story-details/" + id);
      } else {
        history.push("/story-details/" + id);
      }
    } else {
      history.push("/story-details/" + id);
    }
  }

  function follow(followingId, status) {
    console.log("fdkj");
    let userStatus = status ? 0 : 1;
    let userRequest = {
      followingId: followingId,
      status: userStatus,
    };

    console.log(userRequest);

    userFollow(userRequest).then((response) => {
      console.log(response);
      if (response.status !== statusFailure) {
        setFollow(!isFollow);
      }
    });
  }

  const Delete = () => {
    let param = {};
    param.userId = story.userId;
    param.id = story._id;

    DeleteStory(param).then((response) => {
      console.log(response);
    });

    history.push("/");
  };

  console.log(usercomments);

  const closePopup = () => setIsPopupOpen(false);
  const openPopup = () => setIsPopupOpen(true);

  useEffect(() => {
    let token = localStorage.getItem("loginDetails");
    const check = async () => {
      try {
        console.log("token in useeffect", token);
        const res = await axios.post(
          `${Constant.apiBasePath}portfolio/exists`,
          {
            userId: story.userId,
            eventId: id,
          },
          {
            headers: {
              token: token, // Pass token in headers
            },
          }
        );
        if (res.data.status === "Success") {
          setPresent(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

    check();
  }, []);

  const handleAddToPortfolio = async () => {
    let token = localStorage.getItem("loginDetails");

    try {
      const res = await axios.post(
        `${Constant.apiBasePath}portfolio/${id}`,
        {
          userId: story.userId,
        }, // URL with ID
        {
          headers: {
            token: token, // Pass token in headers
          },
        }
      );
      console.log(res.data);
      toast.success("added successfully in portfolio");
      closePopup();
      setPresent(true);
    } catch (error) {
      toast.error("already in portfolio");
      console.error("Error making the request", error);
      closePopup();
    }
  };
  const handleDeleteFromPortfolio = async () => {
    let token = localStorage.getItem("loginDetails");

    try {
      const res = await axios.delete(
        `${Constant.apiBasePath}portfolio/removeEvent/${id}`,
        {
          headers: {
            token: token, // Pass token in headers
          },
        }
      );
      console.log(res.data);
      toast.success("Removed successfully from portfolio");
      closePopup();
      setPresent(false);
    } catch (error) {
      toast.error("error  while deleting");
      console.error("Error making the request", error);
      closePopup();
    }
  };

  return (
    <>
      <Toaster />
      <Helmet>
        <title>{story.name}</title>
        <meta name="description" content={story.userName} />
        <meta property="og:title" content={story.name} />
        <meta property="og:description" content={story.userName} />
        <meta property="og:image" content={story.thumbnail} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={story.name} />
        <meta name="twitter:description" content={story.userName} />
        <meta name="twitter:image" content={story.thumbnail} />
      </Helmet>
      <Layout>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            backgroundColor: "#0f1817",
            height: "auto",
          }}
        >
          <Root bgImg={story.thumbnail}>
            {size.width > 700 ? (
              <div className="story-container">
                <div className="story-header">
                  <div className="story-heading">
                    <div className="heading">
                      <div className="heading-container">
                        {story.name}{" "}
                        {!speech ? (
                          <i
                            className="fa fa-volume-up default-cursor-point sound-icon"
                            onClick={(e) => {
                              handleSpeak(story.description);
                              setSpeech(true);
                            }}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-pause default-cursor-point pause-icon"
                            onClick={(e) => {
                              handleStop();
                              setSpeech(false);
                            }}
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="user-profile">
                      <Link
                        to={
                          story.userId === myprofile._id
                            ? `/profile`
                            : `/userprofile/${story.userId}`
                        }
                      >
                        <div className="user-details">
                          {story.profilePic === "" ? (
                            <img
                              alt="userprofile"
                              className="user-img"
                              src={Default}
                            ></img>
                          ) : (
                            <img
                              alt="userprofile"
                              className="user-img"
                              src={story?.profilePic}
                            ></img>
                          )}
                          <div className="user-name">
                            <div className="name">{story.userName}</div>
                            <div className="time">
                              {moment(story?.createdAt)?.fromNow()}
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div className="follow-button">
                        <button
                          onClick={() => {
                            follow(story.userId, story.userFollow);
                          }}
                          className="follow"
                        >
                          {story.userFollow ? "Unfollow" : "+Follow"}
                        </button>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="icon">
                        <div className="eye">
                          <Clock10Icon className=""></Clock10Icon>{" "}
                          {story.duration}m
                        </div>
                        {category != "" && (
                          <div className="gener">{category}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="thumbnail">
                    <img
                      src={story.thumbnail}
                      alt="thumbnail"
                      className="img"
                    ></img>
                  </div>
                </div>
                {/* for adding the story to portfolio */}

                <div
                  style={{
                    border: "1px solid #ccc",
                    width: "100%",
                    height: "4px",
                    marginTop: "2px",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      width: `${progress}%`,
                      height: "100%",
                      background: "green",
                    }}
                  ></div>
                </div>

                <div className="sound-setting">
                  <select
                    className="select"
                    onChange={handleVoiceChange}
                    value={selectedVoice}
                  >
                    <option value="">Eng</option>
                    {voices.map((voice, index) => (
                      <option key={index} value={voice.name}>
                        {voice.name} ({voice.lang})
                      </option>
                    ))}
                  </select>
                  <select
                    className="select"
                    value={rate}
                    onChange={handleRateChange}
                  >
                    <option value={0.5}>0.5x</option>
                    <option value={1}>1x</option>
                    <option value={1.5}>1.5x</option>
                    <option value={2}>2x</option>
                  </select>

                  {/* for portoflio */}
                  {story.userId === myprofile._id ? (
                    <p
                      style={{
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: "#0f1817",
                      }}
                    >
                      {present ? (
                        <button
                          style={{
                            color: "white",
                            height: "20px",
                            cursor: "pointer",
                            border: "none",
                            fontSize: "30px",
                            borderRadius: "20px",
                            backgroundColor: "#0f1817",
                            position: "relative",
                            bottom: "-18px",
                          }}
                          onClick={handleDeleteFromPortfolio}
                        >
                          -
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "white",
                            height: "20px",
                            cursor: "pointer",
                            border: "none",
                            fontSize: "30px",
                            borderRadius: "20px",
                            backgroundColor: "#0f1817",
                            position: "relative",
                            bottom: "-18px",
                          }}
                          onClick={handleAddToPortfolio}
                        >
                          +
                        </button>
                      )}
                    </p>
                  ) : (
                    ""
                  )}

                  {/* for delete button */}
                  {story.userId === myprofile._id && (
                    <div onClick={() => setOpenDelete(true)}>
                      {" "}
                      <i
                        style={{ color: "white", cursor: "pointer" }}
                        class="fa fa-ellipsis-h"
                      ></i>
                    </div>
                  )}
                </div>

                {/* <hr className="hr"></hr> */}
                <div className="story-description">
                  {story.description?.split("\n").map((str, index) => (
                    <>
                      {str}
                      <br></br>
                    </>
                  ))}
                </div>
                <div className=" story-options">
                  {story.userLike ? (
                    <span>
                      <img
                        alt="heart"
                        onClick={() => like()}
                        style={{
                          height: "25px",
                          width: "25px",
                          marginTop: "0px",
                          cursor: "pointer",
                        }}
                        src={Heart}
                      ></img>{" "}
                      {story.likes}
                    </span>
                  ) : (
                    <span>
                      <HeartIcon
                        style={{ cursor: "pointer" }}
                        onClick={like}
                      ></HeartIcon>{" "}
                      {story.likes}
                    </span>
                  )}
                  <span>
                    <MessageCircle
                      style={{ cursor: "pointer" }}
                    ></MessageCircle>{" "}
                    {usercomments.length}
                  </span>

                  <SendIcon
                    style={{ cursor: "pointer", marginTop: "2px" }}
                    onClick={() => {
                      setShareLink(true);

                      setlink(
                        `https://www.storytent.in/story-details/${story._id}`
                      );
                    }}
                  ></SendIcon>
                </div>
              </div>
            ) : (
              <div className="story-container-mobile">
                <div className="Stroy-header-mobile">
                  <div className="inner-box-mobile">
                    <div className="heading-mobile">
                      <div className="heading-container">
                        {story.name}
                        {!speech ? (
                          <i
                            style={{ marginLeft: "10px" }}
                            className="fa fa-volume-up default-cursor-point sound-icon"
                            onClick={(e) => {
                              handleSpeak(story.description);
                              setSpeech(true);
                            }}
                          ></i>
                        ) : (
                          <i
                            style={{ marginLeft: "10px" }}
                            className="fa fa-pause default-cursor-point pause-icon"
                            onClick={(e) => {
                              handleStop();
                              setSpeech(false);
                            }}
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="user-profile-mobile">
                      <Link
                        to={
                          story.userId === myprofile._id
                            ? `/profile`
                            : `/userprofile/${story.userId}`
                        }
                        className="user-details"
                      >
                        {story.profilePic === "" ? (
                          <img
                            alt="userprofile"
                            className="user-img"
                            src={Default}
                          ></img>
                        ) : (
                          <img
                            alt="userprofile"
                            className="user-img"
                            src={story?.profilePic}
                          ></img>
                        )}

                        <div className="user-name">
                          <div className="name">{story.userName}</div>
                          <div className="time">
                            {moment(story?.createdAt)?.fromNow()}
                          </div>
                        </div>
                      </Link>
                      <div className="follow-button">
                        <button
                          onClick={() => {
                            follow(story.userId, story.userFollow);
                          }}
                          className="follow-mobile"
                        >
                          {story.userFollow ? "Unfollow" : "+Follow"}
                        </button>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="icon">
                        <div className="eye">
                          <Clock10Icon className=""></Clock10Icon>{" "}
                          {story.duration}m
                        </div>
                        {category !== "" && (
                          <div className="gener">{category}</div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    border: "1px solid #ccc",
                    width: "100%",
                    height: "4px",
                    marginTop: "1px",
                  }}
                >
                  <div
                    style={{
                      width: `${progress}%`,
                      height: "100%",
                      background: "green",
                    }}
                  ></div>
                </div>

                <div className="sound-setting">
                  <select
                    className="select"
                    onChange={() => handleVoiceChange()}
                    value={selectedVoice}
                  >
                    <option value="">Eng</option>
                    {voices.map((voice, index) => (
                      <option key={index} value={voice.name}>
                        {voice.name} ({voice.lang})
                      </option>
                    ))}
                  </select>
                  <select
                    className="select"
                    value={rate}
                    onChange={() => handleRateChange()}
                  >
                    <option value={0.5}>0.5x</option>
                    <option value={1}>1x</option>
                    <option value={1.5}>1.5x</option>
                    <option value={2}>2x</option>
                  </select>

                  {story.userId === myprofile._id && (
                    <div onClick={() => setOpenDelete(true)}>
                      {" "}
                      <i
                        style={{ color: "white" }}
                        class="fa fa-ellipsis-h"
                      ></i>
                    </div>
                  )}
                </div>
                <div className="story-description-mobile">
                  {story.description?.split("\n").map((str, index) => (
                    <>
                      {str}
                      <br></br>
                    </>
                  ))}
                </div>
                <div className=" story-options">
                  {story.userLike ? (
                    <span>
                      <img
                        alt="heart"
                        onClick={like}
                        style={{
                          height: "25px",
                          width: "25px",
                          marginTop: "0px",
                          cursor: "pointer",
                        }}
                        src={Heart}
                      ></img>{" "}
                      {story.likes}
                    </span>
                  ) : (
                    <span>
                      <HeartIcon
                        style={{ cursor: "pointer" }}
                        onClick={like}
                      ></HeartIcon>{" "}
                      {story.likes}
                    </span>
                  )}
                  <span>
                    <MessageCircle></MessageCircle> {usercomments.length}
                  </span>

                  <SendIcon
                    style={{ cursor: "pointer", marginTop: "2px" }}
                    onClick={() => {
                      setShareLink(true);

                      setlink(
                        `https://www.storytent.in/story-details/${story._id}`
                      );
                    }}
                  ></SendIcon>
                </div>
              </div>
            )}

            <div className="comment-section">
              <form className="form">
                <input
                  value={comment}
                  onChange={(e) => setcomment(e.target.value)}
                  placeholder="Add a comment"
                  className="comment-input"
                ></input>
                <button onClick={(e) => SubmitComment(e)} className="send-btn">
                  <SendHorizonalIcon></SendHorizonalIcon>
                </button>
              </form>
              {usercomments.length > 0 ? (
                <div className="comments">
                  {usercomments.length > 0 &&
                    usercomments &&
                    usercomments.map((data) => {
                      const duration =
                        Duration(data.usercomment.createdAt) || 0;

                      return (
                        <div className="comment-cart">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="user-comment">
                              <img
                                className="profile-img"
                                alt="profile"
                                src={data.usercomment.userProfilePicture}
                              ></img>
                              <div className="profile-name">
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {data.usercomment.userName}
                                </span>
                                {/* <span style={{fontSize:"10px",marginTop:"-5px"}}>{moment(data.usercomment.createdAt)?.fromNow()}</span> */}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                className="likeicon"
                                onClick={() =>
                                  CommentLike(data.usercomment._id)
                                }
                              >
                                <ThumbsUp
                                  style={{ height: "15px", width: "15px" }}
                                />
                              </span>

                              {data.usercomment.userId === myprofile._id && (
                                <div style={{ position: "relative" }}>
                                  <i
                                    style={{
                                      color: "white",
                                      cursor: "pointer",
                                      marginTop: "20px",
                                    }}
                                    className="fa fa-ellipsis-h"
                                    onClick={() =>
                                      toggleMenu(data.usercomment._id)
                                    }
                                  ></i>

                                  {activeMenu === data.usercomment._id && (
                                    <div
                                      style={{
                                        display: "flex",
                                        position: "absolute",
                                        top: "35px",
                                        right: "-15px",
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                                        zIndex: 100,
                                      }}
                                    >
                                      <span
                                        className="delete-comment"
                                        style={{
                                          cursor: "pointer",
                                          display: "flex",
                                        }}
                                        onClick={() => {
                                          CommentDelete(data.usercomment._id);
                                          setActiveMenu(null);
                                        }}
                                      >
                                        <Trash
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                            color: "red",
                                            marginTop: "5px",
                                          }}
                                        />
                                        <div
                                          style={{
                                            color: "white",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          Delete
                                        </div>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="comment-text">
                            {data.usercomment.description}
                          </div>
                          <div className="comment-icons">
                            <div>{duration} days</div>
                            <div>{data.usercomment.likes.length} Likes</div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setReplyOpen(data.usercomment._id);
                              }}
                            >
                              Reply
                            </div>
                          </div>
                          {data.usersubcomments.length > 0 ? (
                            replyopen !== data.usercomment._id ? (
                              <div
                                className="view"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setViewAll(data.usercomment._id);
                                  setreplyopen(data.usercomment._id);
                                  console.log(
                                    "data.usersubcomments",
                                    data.usersubcomments
                                  );
                                }}
                              >
                                ----View Replies
                              </div>
                            ) : (
                              <div
                                className="view"
                                style={{ cursor: "pointer" }}
                                onClick={() => setreplyopen("")}
                              >
                                ---Hide Replies
                              </div>
                            )
                          ) : null}
                          {ReplyOpen === data.usercomment._id && (
                            <form className="subform">
                              <input
                                value={subComment}
                                onChange={(e) => setsubComment(e.target.value)}
                                placeholder="Add a comment"
                                className="subcomment-input"
                              ></input>
                              <button
                                type="button"
                                onClick={() =>
                                  SubmitSubComment(data.usercomment._id)
                                }
                                className="subsend-btn"
                              >
                                Reply
                              </button>
                            </form>
                          )}

                          {replyopen &&
                            viewAll === data.usercomment._id &&
                            data.usersubcomments.length > 0 &&
                            data.usersubcomments.map((data) => {
                              const durationsub = Duration(data.createdAt);

                              return (
                                <div
                                  style={{ marginLeft: "70px", height: "auto" }}
                                >
                                  <div className="sub-comment-box">
                                    <div>
                                      {data.userProfilePicture &&
                                      data.userProfilePicture === "" ? (
                                        <img
                                          style={{
                                            borderRadius: "100px",
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          alt=""
                                          src={Default}
                                        ></img>
                                      ) : (
                                        <img
                                          style={{
                                            borderRadius: "100px",
                                            width: "30px",
                                            height: "30px",
                                          }}
                                          alt=""
                                          src={data.userProfilePicture}
                                        ></img>
                                      )}
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        {data.userName}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        className="likeicon"
                                        onClick={() => CommentLike(data._id)}
                                      >
                                        <ThumbsUp
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                        ></ThumbsUp>
                                      </span>

                                      {data.userId === myprofile._id && (
                                        <div style={{ position: "relative" }}>
                                          <i
                                            style={{
                                              color: "white",
                                              cursor: "pointer",
                                              marginTop: "20px",
                                            }}
                                            className="fa fa-ellipsis-h"
                                            onClick={() => {
                                              toggleMenu(data._id);
                                              console.log(
                                                "subcomment Data",
                                                data
                                              );
                                            }}
                                          ></i>

                                          {activeMenu === data._id && (
                                            <div
                                              style={{
                                                display: "flex",
                                                position: "absolute",
                                                top: "35px",
                                                right: "-15px",
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                padding: "10px",
                                                boxShadow:
                                                  "0 2px 10px rgba(0,0,0,0.1)",
                                                zIndex: 100,
                                              }}
                                            >
                                              <span
                                                className="delete-comment"
                                                style={{
                                                  cursor: "pointer",
                                                  display: "flex",
                                                }}
                                                onClick={() => {
                                                  SubCommentDelete(data._id);
                                                  setActiveMenu(null);
                                                }}
                                              >
                                                <Trash
                                                  style={{
                                                    height: "15px",
                                                    width: "15px",
                                                    color: "red",
                                                    marginTop: "5px",
                                                  }}
                                                />
                                                <div
                                                  style={{
                                                    color: "white",
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  Delete
                                                </div>
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: "35px",
                                      marginTop: "-10px",
                                      color: "white",
                                    }}
                                  >
                                    {data.description}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      marginLeft: "33px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <div style={{}}>{durationsub} days</div>
                                    <div style={{}}>
                                      {data.likes.length} Likes
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div>No comments</div>
                </div>
              )}
            </div>

            {sharelink && (
              <div ref={divRef} className="share-box">
                <div
                  onClick={() => setShareLink(false)}
                  style={{
                    position: "absolute",
                    right: "0px",
                    cursor: "pointer",
                  }}
                >
                  <Plus
                    style={{
                      transform: "rotate(45deg)",
                      color: "white",
                      cursor: "pointer",
                    }}
                  ></Plus>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  Share
                </div>
                <div className="share-link-icon">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      copyToClipboard(link);
                      setShareLink(false);
                    }}
                    className="icon-container"
                  >
                    <Link2></Link2>
                  </span>
                </div>
                <div style={{ textAlign: "center", color: "white" }}>
                  Copy Link
                </div>
              </div>
            )}

            {openDelete && (
              <div className="Delete-Pop-up">
                <div
                  onClick={() => setOpenDelete(false)}
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "2px",
                    transform: "rotate(45deg)",
                    padding: "2px",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  <Plus></Plus>
                </div>
                <div
                  className="delete"
                  onClick={() => Delete()}
                  style={{
                    display: "flex",
                    gap: "20px",
                    cursor: "pointer",
                    color: "red",
                  }}
                >
                  <Trash></Trash> <span style={{ color: "white" }}>Delete</span>
                </div>
              </div>
            )}
          </Root>
        </div>
      </Layout>
    </>
  );
}

export default StroyDetailsNew;

const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Ensures the modal is above other elements
  },
  modalContent: {
    backgroundColor: "#444141",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    width: "300px", // Adjust as necessary
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
  },
  addButton: {
    padding: "10px 20px",
    backgroundColor: "gray",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
};

const Root = styled.div`
  min-height: 100vh;
  height: auto;
  background-color: #0f1817;
  margin-top: -16px;
  display: flex;
  width: 100%;
  overflow: hidden;
  max-width: 1300px;
  position: relative;
  flex-direction: column;
  ${"" /* justify-content:center; */}
  align-items:center;

  .Delete-Pop-up {
    width: 200px;
    height: 100px;
    background-color: #07262b;
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    transform: translate(-50%, -50%);
  }

  .sub-comment-box {
    display: flex;
    justify-content: space-between;
    width: 98%;
    color: white;

    @media screen and (min-width: 350px) and (max-width: 1100px) {
      padding-right: 10px;
    }
  }

  .share-link-icon {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delete:hover {
    background-color: gray;
    padding: 2px;
    width: 150px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .share-box {
    width: 300px;
    height: 130px;
    border-radius: 10px;
    background-color: #023020;
    position: fixed;
    top: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .follow-button {
  }

  .icon-container {
    background-color: #e6edea;
    padding: 10px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 100px;
  }

  .gener {
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    color: white;
  }

  .eye {
    color: white;
    font-size: 12px;
  }

  .likeicon {
    color: white;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-right: 15px;
  }

  .user-name {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .like {
    width: 25px;
    height: 25px;
  }

  .view {
    margin-left: 75px;
    margin-top: 10px;
  }

  ${
    "" /* .story-heading{
  position:absolute;
  bottom:0;
  left:0;
} */
  }

  .comment-icons {
    display: flex;
    margin-left: 60px;
    font-size: 12px;
    gap: 12px;
  }
  .hr {
    border: 1px solid;
    opacity: 0.5;
  }

  .comment-text {
    color: white;
    margin-left: 55px;
    margin-top: -23px;
    font-size: 14px;
    height: auto;
  }

  .profile-name {
    color: white;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
  }

  .sound-setting {
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 20px;
    margin-top: 20px;
    @media (min-width: 350px) and (max-width: 700px) {
      margin-right: 20px;
    }
  }

  .select {
    width: 80px;
    background-color: gray;
    opacity: 0.5;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
    border: none;
    color: white;
    border-radius: 20px;
    text-align: center;
  }

  .story-options {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    color: white;
    margin-bottom: 10px;
    margin-left: 10px;
    @media (min-width: 350px) and (max-width: 700px) {
      margin-left: 30px;
    }
  }

  ${"" /* mobile responsive */}

  .follow-mobile {
    padding-left: 5px;
    padding-right: 5px;
    background: transparent;
    outline: none;
    border: 1px solid white; /* Added a solid border with 1px width */
    color: white;
    margin-top: 5px;
  }

  .desc {
    color: white;
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }

  .text {
    margin-top: 20px;
    margin-left: 20px;
    color: white;
  }

  .user-profile-mobile {
    display: flex;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }

  .drop {
    position: absolute;
    width: 390px;
    height: 100px;
    background-color: #263a38;
    z-index: 100;
  }

  .heading-mobile {
    display: flex;
    color: white;
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 24px;
    line-height: 1.2;
    letter-spacing: -0.3px;

    @media (min-width: 350px) and (max-width: 450px) {
      font-size: 18px;
    }
  }

  .inner-box-mobile {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80%;
    max-width: 400px;
    min-height: 120px;
    padding-bottom: 20px;
    height: auto;
    @media (min-width: 350px) and (max-width: 600px) {
      width: 85%;
      max-width: 350px;
      min-height: 120px;
    }
  }

  .story-container-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .img-cover {
    width: 100%;
    height: 100%;
  }

  .story-description-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    text-align: justify;
    min-height: 250px;
    height: auto;
    color: white;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    word-wrap: break-word;
    white-space: normal;
  }

  .Stroy-header-mobile {
    position: relative;
    width: 100%;
    height: 250px;
    background: transparent;
    background-image: ${(props) => `url(${props.bgImg})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-item: end;
    justify-content: end;
  }

  .Stroy-header-mobile::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${"" /* desktop responsive */}
  .comment-input {
    width: 100%;
    border-radius: 20px;
    outline: none;
    background-color: #0f1817;
    border: 1px solid #263a38;
    padding: 5px;
    padding-left: 10px;
    color: white;
    display: initial;
  }

  .subcomment-input {
    width: 100%;
    border-radius: 20px;
    outline: none;
    background-color: #0f1817;
    border: 1px solid #263a38;
    padding: 5px;
    padding-left: 10px;
    color: white;
    margin-left: 75px;
    margin-top: 10px;

    @media (min-width: 350px) and (max-width: 450px) {
      font-size: 11px;
    }
  }

  .profile-img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
  }
  .comment-cart {
    width: 100%;
    height: auto;
  }
  .comments {
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .send-btn {
    position: absolute;
    right: 7px;
    top: 4px;
    border: none;
    outline: none;
    background-color: #0f1817;
    color: white;
  }

  .subsend-btn {
    position: absolute;
    right: 15px;
    top: 13px;
    border: none;
    outline: none;
    background-color: #0f1817;
    color: white;

    @media (min-width: 350px) and (max-width: 450px) {
      right: 8px;
      top: 15px;
      font-size: 10px;
    }
  }

  .story-description {
    padding: 5px;
    margin-bottom: 20px;
    ${"" /* padding-left:10px; */}
    padding-right:20px;
    color: white;
    height: auto;
    text-align: justify;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    font-size: 20px;
    min-height: 200px;
    word-wrap: break-word;
    white-space: normal;
  }

  .icon {
    margin-top: 10px;
    gap: 15px;
    display: flex;
    margin-left: 7px;
    @media (min-width: 350px) and (max-width: 700px) {
      margin-left: 25px;
    }
  }

  .heading {
    display: flex;
    color: white;
    font-size: 24px;
    margin-left: 1px;
    line-height: 1;
    letter-spacing: -0.4px;
  }

  .heading-container {
    ${"" /* display: inline-flex; */}
    align-items: center;
    gap: 10px;
  }

  .name {
    color: white;
    line-height: 1.2;
    letter-spacing: -0.4px;
    margin-top: 5px;
    @media (min-width: 350px) and (max-width: 450px) {
      font-size: 12px;
      margin-top: 5px;
    }

    @media (min-width: 701px) and (max-width: 800px) {
      font-size: 14px;
    }
  }
  .follow {
    padding-left: 5px;
    padding-right: 5px;
    background-color: #0f1817;
    outline: none;
    z-index: 1000;

    border: 1px solid white; /* Added a solid border with 1px width */
    color: white; /* Changed color to white for better contrast */
    margin-top: 5px;
  }
  .time {
    margin-top: -6px;
    font-size: 10px;
    color: white;
  }
  .user-img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  .user-details {
    display: flex;
    gap: 10px;
  }

  .story-container {
    width: 80%;
    height: auto;
    margin-top: 50px;
  }

  .sound-icon {
    margin-left: 5px;
  }
  .img {
    width: 250px;
    height: 160px;
    border-radius: 20px;
  }

  .story-header {
    width: 100%;
    height: 200px;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    ${"" /* align-items:center; */}
  }

  .comment-section {
    width: 85%;
    padding-left: 20px;
    padding-right: 20px;

    ${"" /* min-height:5000px; */}
    height:auto;

    @media (min-width: 350px) and (max-width: 700px) {
      width: 100%;
    }
  }
  .form {
    position: relative;
  }

  .subform {
    position: relative;
    display: flex;
    justify-content: end;
  }

  .user-comment {
    display: flex;
    gap: 10px;
    padding: 10px;
  }
  .user-profile {
    display: flex;
    margin-top: 20px;
    gap: 30px;
  }
`;
