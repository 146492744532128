import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Context from "../Context";
import Image from "../Images/Defaultprofile.png";
import Layout from "../Layout/Layout";
import HeartImg from "../Images/heart.png";
import {
  getHomePagePosts,
  getHomePagePostsAdditional,
  userLike,
} from "../views/utilities/ApiCalls";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../src/css/style.css";
import "../css/Home.css";
import { Heart, Link2, MessageCircle, Plus, Send } from "lucide-react";
import Default from "../Images/Defaultprofile.png";
import { useModel } from "../hooks/modelcontex";
import ReactGA from "react-ga4";
import "moment/locale/hi";
import "moment/locale/en-gb";
import { CustomCarousel } from "../Componets/CustomCarousel";
import { SpotlightCarousel1 } from "../Componets/SpotLight1";
import { AntiMatterCarousel } from "../Componets/AnitMatterCarousel";

export default function Home() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sharelink, setSharelink] = useState(false);
  const [link, setLink] = useState("");
  const [like, setLike] = useState("");

  const observer = useRef();
  const shareRef = useRef(null);
  const { state } = useContext(Context);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { myprofile } = useModel();

  useEffect(() => {
    const checklogged = async () => {
      const token = await localStorage.getItem("loginDetails");
      if (!token) {
        // Save the current path to localStorage before redirecting
        localStorage.setItem("redirectAfterLogin", window.location.pathname);
        history.push("/login");
      }
    };
    checklogged();
  }, []);

  // Set up intersection observer for infinite scroll
  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // Handle initial data fetch and pagination
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        setError(null);

        let newPosts;
        if (page === 1) {
          const response = await getHomePagePosts(page);
          newPosts = response.data || [];

          if (newPosts.length === 0) {
            const additionalResponse = await getHomePagePostsAdditional(1);
            newPosts = [
              ...(additionalResponse.data.poems || []),
              ...(additionalResponse.data.stories || []),
            ];
          }
        } else {
          const response = await getHomePagePostsAdditional(page);
          newPosts = [
            ...(response.data.poems || []),
            ...(response.data.stories || []),
          ];
        }

        if (newPosts.length === 0) {
          setHasMore(false);
        } else {
          setPosts((prevPosts) => {
            const uniquePosts = newPosts.filter(
              (newPost) => !prevPosts.some((post) => post._id === newPost._id)
            );
            return [...prevPosts, ...uniquePosts];
          });
        }
      } catch (err) {
        setError("Failed to fetch posts");
        console.error("Error fetching posts:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [page]);

  // Handle like functionality
  const handleLike = async (data) => {
    const likeType = data.type === "story" ? 1 : 2;

    const likeRequest = {
      moduleId: data._id,
      type: likeType,
      user_id: "",
      title: data.name,
      description: data.description,
      thumbnail: data.thumbnail,
    };

    try {
      const response = await userLike(likeRequest);
      const isLike = response.message === "Like";

      setPosts((currentPosts) =>
        currentPosts.map((post) => {
          if (post._id === data._id) {
            return {
              ...post,
              no_of_likes: isLike
                ? (post.no_of_likes || 0) + 1
                : post.no_of_likes - 1,
              userLike: isLike ? 1 : 0,
            };
          }
          return post;
        })
      );

      setLike(isLike);

      ReactGA.event({
        category: "User Interaction",
        action: data.userLike === 1 ? "Unlike" : "Like",
        label: likeType === 1 ? "Story Like" : "Poem Like",
        value: data._id,
      });
    } catch (error) {
      console.error("Error handling like:", error);
    }
  };

  // Handle share link functionality
  const handleClickOutside = (event) => {
    if (shareRef.current && !shareRef.current.contains(event.target)) {
      setSharelink(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  }, []);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSharelink(false);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };
  return (
    <Layout>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#0f1817",
          minHeight: "100vh",
        }}
      >
        {/* <SpotlightCarousel/> */}
        <SpotlightCarousel1 />
        <CustomCarousel />
        {/* <AntiMatterCarousel /> */}
        {/* <ContestCarousel /> */}
        {/* <OpenMicCarousel /> */}
        {/* <EventCarousel/> */}
        <Root>
          <div className={`posts ${sharelink && "opacity"}`}>
            {posts.map((data, index) => {
              const isLastElement = posts.length === index + 1;
              return (
                <div
                  key={data._id}
                  ref={isLastElement ? lastPostElementRef : null}
                  style={{ color: "white" }}
                  className="post-container"
                >
                  <Link
                    to={
                      myprofile._id === data.userId
                        ? `/profile`
                        : `/userprofile/${data.userId}`
                    }
                    className=" user-profile"
                  >
                    {data.profilePic === "" ? (
                      <img
                        className="profile"
                        src={Default}
                        alt="profile"
                      ></img>
                    ) : (
                      <img
                        className="profile"
                        src={data?.profilePic}
                        alt="profile"
                      ></img>
                    )}
                    <div className="name">
                      <span>{data.authorName}</span>
                      <span className="duration">
                        {moment(data?.createdAt)?.fromNow()}
                        {console.log(moment(data?.createdAt)?.fromNow())}
                      </span>
                    </div>
                  </Link>
                  {state.token ? (
                    <div
                      onClick={() =>
                        history.push(`/${data.type}-details/${data._id}`)
                      }
                      style={{ color: "white" }}
                      className="heading"
                    >
                      {data.name}
                    </div>
                  ) : (
                    <div
                      onClick={() => history.push(`/login`)}
                      style={{ color: "white" }}
                      className="heading"
                    >
                      {data.name}
                    </div>
                  )}

                  {state.token ? (
                    <div
                      onClick={() =>
                        history.push(`/${data.type}-details/${data._id}`)
                      }
                      className="main-content"
                    >
                      <div className="post-info">
                        <p className=" text">{data.description}</p>
                        {/* {data} */}
                      </div>
                      <div className="thumbnail-img">
                        <img alt="" className="img" src={data.thumbnail}></img>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => history.push(`/login`)}
                      className="main-content"
                    >
                      <div className="post-info">
                        <p className=" text">{data.description}</p>
                        {/* {data} */}
                      </div>
                      <div className="thumbnail-img">
                        <img alt="" className="img" src={data.thumbnail}></img>
                      </div>
                    </div>
                  )}

                  {state.token ? (
                    <div className="icons">
                      {data.userLike ? (
                        <span>
                          <img
                            alt="heart"
                            onClick={() => like(data)}
                            style={{
                              height: "25px",
                              width: "25px",
                              marginTop: "0px",
                              cursor: "pointer",
                            }}
                            src={HeartImg}
                          />
                          {data.no_of_likes ?? 0}
                        </span>
                      ) : (
                        <span>
                          <Heart
                            style={{ cursor: "pointer" }}
                            onClick={() => like(data)}
                          />
                          {data.no_of_likes ?? 0}
                        </span>
                      )}

                      <span>
                        <MessageCircle
                          onClick={() =>
                            history.push(`/${data.type}-details/${data._id}`)
                          }
                        ></MessageCircle>{" "}
                        {data.number_of_comments}{" "}
                      </span>
                      <Send
                        style={{
                          width: "22px",
                          height: "22px",
                          marginTop: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSharelink(true);
                          setLink(
                            `https://www.storytent.in/${data.type}-details/${data._id}`
                          );
                        }}
                      ></Send>
                    </div>
                  ) : (
                    <Link
                      style={{ color: "white" }}
                      to={`/login`}
                      className="icons"
                    >
                      <Heart></Heart>
                      <MessageCircle></MessageCircle>
                      <Send></Send>
                    </Link>
                  )}

                  <hr className="hr"></hr>
                </div>
              );
            })}
            {loading && (
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  color: "white",
                }}
              >
                {t("Home.Loading")}
              </div>
            )}

            {error && (
              <div
                style={{ fontSize: "20px", textAlign: "center", color: "red" }}
              >
                {error}
              </div>
            )}

            {!hasMore && (
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  color: "white",
                }}
              >
                No more posts to load
              </div>
            )}
          </div>

          {sharelink && (
            <div ref={shareRef} className="share-box">
              <div
                onClick={() => setSharelink(false)}
                style={{ position: "absolute", right: "0px" }}
              >
                <Plus
                  style={{
                    transform: "rotate(45deg)",
                    color: "white",
                    cursor: "pointer",
                  }}
                ></Plus>
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "20px",
                }}
              >
                {t("ShareLink.Share")}
              </div>

              <div>
                <div className="share-link-icon">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      copyToClipboard(link);
                      setSharelink(false);
                    }}
                    className="icon-container"
                  >
                    <Link2></Link2>
                  </span>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  {t("ShareLink.CopyLink")}
                </div>
              </div>
            </div>
          )}
        </Root>
      </div>
    </Layout>
  );
}

const Root = styled.div`
  min-height: 582px;
  height: auto;
  display: flex;
  background-color: #0f1817;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .share-box {
    width: 300px;
    height: 120px;
    border-radius: 10px;
    background-color: #023020;
    position: fixed;
    top: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon-container {
    background-color: #e6edea;
    padding: 10px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 100px;
  }

  .share-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .opacity {
    opacity: 0.2;
  }

  .post-container {
    // cursor: pointer;
  }
  .post-info {
    flex-basis: 2/3;
    cursor: pointer;
    width: 100%;
    height: 150px;
    overflow: hidden;
    @media (min-width: 350px) and (max-width: 850px) {
      height: 80px;
      flex-basis: 3/4;
      width: 100%;
      min-width: 180px;
    }
  }
  p {
    color: white;
  }

  .main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    cursor: pointer;
    width: 100%;
    gap: 10px;
    padding-left: 5px;
    padding-right: 5px;

    @media (min-width: 350px) and (max-width: 850px) {
      height: 100px;
    }
  }

  .thumbnail-img {
    display: flex;
    flex-basis: 1/3;

    @media (min-width: 350px) and (max-width: 850px) {
      height: 90px;
      flex-basis: 1/4;
      width: 100%;
      border-radius: 12px;
      width: 180px;
    }
  }

  .hr {
    width: 100%;
    border: none;
    border: 1px solid white;
    margin-bottom: 20px;
    opacity: 0.5;
  }

  .icons {
    gap: 50px;
    margin-left: 10px;
    display: flex;
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .text {
    width: 95%; /* Set container width */
    margin-left: 5px; /* Add left margin */
    line-height: 1.2; /* Set line height */
    letter-spacing: -0.5px; /* Set letter spacing */

    overflow: hidden; /* Hide any content that overflows the container */
    display: -webkit-box; /* Display as WebKit box */
    -webkit-box-orient: vertical; /* Orient the box vertically */
    -webkit-line-clamp: 8; /* Limit the block to 4 lines */
    white-space: normal; /* Allow text to wrap */
    text-overflow: ellipsis; /* Truncate text with ellipsis (...) */
    text-align: justify;
    @media (min-width: 350px) and (max-width: 850px) {
      -webkit-line-clamp: 4; /* Adjust the line clamp for smaller screens */
    }
  }

  .img {
    width: 100%;
    height: 100%;
    height: 150px;
    width: 350px;
    @media (min-width: 350px) and (max-width: 850px) {
      height: 90px;
      width: 180px;
    }
  }

  .heading {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 30px;
    line-height: 1.1;
    ${"" /* font-weight:bold; */}
    ${"" /* font-family: sans-serif; */}
@media (min-width: 350px) and (max-width: 850px) {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  .name {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: #fff;
    // font-size:15px;
    .duration {
      font-size: 10px;
    }
  }
  .user-profile {
    display: flex;
    gap: 10px;
    color: #73807f;
    padding-left: 5px;
    padding-right: 5px;
  }

  .profile {
    height: 45px;
    width: 45px;
    border-radius: 50px;
  }

  .posts {
    margin-top: 30px;
    width: 90%;
    height: auto;
  }

  .input {
    width: 100%;
    padding: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    outline: none;
    border: none;
    display: block;
    padding-left: 10px;
    background-color: #263a38;
  }

  .share-button {
    color: white;
    padding: 5px;
    padding-right: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    outline: none;
    border: none;
    background-color: #5a6f6d;
  }
  .input-container {
    display: flex;
    width: 100%;

    ${"" /* justify-content:space-between; */}
  }

  .label {
    color: white;
  }

  .form {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .slider {
    background-color: #263a38;
    color: white;
    ${"" /* margin-top:75px; */}

    width:100%;
    height: 150px;
    display: flex;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .inside {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider-container {
    margin-top: 75px;
    width: 80%;
    height: auto;
    min-height: 150px;
    ${"" /* max-width:600px; */}
    ${"" /* background-color:red; */}
  }
`;
