// import React from 'react';
// import Poll from "../views/Poll";

// const Open2 = () => {
//   return (
//     <div>
//        <div className="video-container1">
//            <div className="player">
//             <center>
//             <p className="player1">OPEN MIC</p>
//             </center>
//            {/* {ageConfirmed && ( */}
//          <iframe
//           width="90%"
//           height="100%"
//           src="https://www.youtube.com/embed/03uC95KDKqw?si=v5m8ummbb4VXXiu5?controls=0&rel=0"
//           title="YouTube video player"
//           frameborder="10"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//           allowfullscreen
//           style={{ marginLeft: "23px",borderRadius:"12px",height:"87%" }}

//         ></iframe>

//       {/* )} */}
//       </div>
//       <Poll />

//       {/* End Testomonial Section */}
//       {/* <div className="share"  style={{ textAlign: "right" }}>
//     <ShareButton />
//   </div>*/}
// </div>
//     </div>
//   )
// }

// export default Open2;

// import React, { useEffect, useState } from 'react';
// import Poll from "../views/Poll";
// import axios from 'axios';
// import { getOpenMicDataById2 } from './utilities/ApiCalls';

// const Open2 = () => {
//   const [openMicData, setOpenMicData] = useState(null);

//   useEffect(() => {
//     fetchOpenMicData();
//   }, []);

//   const fetchOpenMicData = async () => {
//     try {
//       const response = await getOpenMicDataById2('65fd56c6afaf26c4731b9ef1');
//       setOpenMicData(response.data);
//     } catch (error) {
//       console.error('Error fetching open mic data:', error);
//     }
//   };

//   return (
//     <div>
//       <div className="video-container1">
//         <div className="player">
//           <center>
//             <p className="player1">OPEN MIC</p>
//           </center>
//           {openMicData && (
//             <iframe
//               width="90%"
//               height="100%"
//               src={openMicData.videoLink}
//               title="YouTube video player"
//               frameBorder="10"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//               allowFullScreen
//               style={{ marginLeft: "23px", borderRadius: "12px", height: "87%" }}
//             ></iframe>
//           )}
//         </div>
//         <Poll />
//       </div>
//     </div>
//   );
// }

// export default Open2;

import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import Poll from "./Poll";
import axios from "axios";
import { getOpenMicDataById2 } from "./utilities/ApiCalls";
import Countdown from "react-countdown";
import Layout from "../Layout/Layout";
import { RxCross2 } from "react-icons/rx";
import { apiBasePath } from "./Constant";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
const timestamesdata = [
  {
    index: "Open Mic 1",
    stamps: [
      { time: "0:00", title: "INTRO" },
      { time: "0:41", title: "HOST SIMRAN" },
      { time: "4:36", title: "ARPIT" },
      { time: "9:17", title: "HOST SIMRAN" },
      { time: "10:37", title: "MANSI" },
      { time: "13:45", title: "SIFAR" },
      { time: "21:49", title: "HOST SIMRAN" },
      { time: "31:09", title: "VINAY" },
      { time: "35:19", title: "SAURAV" },
      { time: "48:59", title: "HOST SIMRAN" },
      { time: "55:33", title: "APURVA" },
      { time: "1:02:34", title: "HOST SIMRAN" },
      { time: "1:04:53", title: "AKHILESH" },
      { time: "1:11:01", title: "HOST SIMRAN" },
      { time: "1:12:41", title: "SARIKA" },
      { time: "1:22:43", title: "HOST SIMRAN" },
      { time: "1:24:58", title: "SUNIL" },
      { time: "1:27:58", title: "HOST SIMRAN" },
    ],
  },
  {
    index: "Open Mic 2",
    stamps: [
      { time: "0:00", title: "INTRO" },
      { time: "1:02", title: "HOST PREETA" },
      { time: "2:18", title: "KUSUM" },
      { time: "9:27", title: "YOUG" },
      { time: "15:42", title: "TANVI" },
      { time: "24:29", title: "HRICHAYAGGESH" },
      { time: "32:50", title: "BHOUMIK" },
      { time: "42:14", title: "VIJAY" },
      { time: "46:25", title: "HIMANSHI" },
      { time: "54:26", title: "VIVEK" },
      { time: "1:01:05", title: "ANIMESH" },
      { time: "1:16:45", title: "KAVERI" },
      { time: "1:24:42", title: "HEMANT" },
      { time: "1:34:55", title: "VIVEK" },
      { time: "1:38:55", title: "BHOUMIK" },
    ],
  },
  {
    index: "Open Mic 3",
    stamps: [
      { time: "0:00", title: "HOST BHOUMIK" },
      { time: "1:08", title: "INTRO" },
      { time: "6:00", title: "VIKASH" },
      { time: "9:44", title: "REHAN" },
      { time: "11:20", title: "HOST BHOUMIK" },
      { time: "13:04", title: "MUDIT" },
      { time: "19:04", title: "HOST BHOUMIK" },
      { time: "23:09", title: "PAYAL" },
      { time: "31:05", title: "HOST BHOUMIK" },
      { time: "33:44", title: "VANSHIKA" },
      { time: "41:57", title: "HOST BHOUMIK" },
      { time: "43:18", title: "REHAN" },
      { time: "48:50", title: "HOST BHOUMIK" },
      { time: "51:23", title: "ISHITA" },
      { time: "56:22", title: "HOST BHOUMIK" },
      { time: "57:41", title: "PRIYANKA" },
      { time: "1:04:16", title: "HOST BHOUMIK" },
      { time: "1:05:10", title: "SATWIK" },
      { time: "1:10:31", title: "HOST BHOUMIK" },
      { time: "1:11:58", title: "ARKA" },
    ],
  },
];

const OpenMic = (props) => {
  const [openMicData, setOpenMicData] = useState(null);
  const [date, setDate] = useState("2024-12-12");
  const [showViewResultButton, setShowViewResultButton] = useState(false);
  const [winnersIdArray, setWinnersIdArray] = useState([]);
  const [winners, setWinners] = useState([]);
  const [isDescriptionOpen, setIsdescriptionopen] = useState(false);
  const [url, seturl] = useState(0);
  const [Timestamp, setTimeStamp] = useState({});
  const [showWinners, setShowWinners] = useState(false)

  let { id } = useParams();

  const { t } = useTranslation();
  useEffect(() => {
    if (id) {
      fetchOpenMicData(id);
    }
  }, [id]);

  console.log("open mic");
  console.log(openMicData);

  const [copySuccess, setCopySuccess] = useState(false);

  // Function to copy text to clipboard

  const copyToClipboard = async (text) => {
    navigator.clipboard.writeText(text);
    console.log(text);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };
  useEffect(() => {
    const t = openMicData?.about.split("\n");
    console.log(t);
    const timestamp = t?.map((data) => {
      const timesArray = data.split(" ");
      const time = timesArray[0];
      const title = timesArray.slice(1).join(" ");
      return { time: time, title: title };
    });
    setTimeStamp(timestamp);
  }, [openMicData]);

  function pad(n) {
    return n < 10 ? "0" + n : n;
  }

  const handleCountdownComplete = () => {
    setShowViewResultButton(true);
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      handleCountdownComplete();
      return null;
    } else {
      // Render a countdown
      return (
        <span>
          {days} day {hours} hr {minutes} min {seconds} sec
        </span>
      );
    }
  };
  const changetosec = (str) => {
    const time = str.split(":");
    console.log(time);
    let timeinsec;
    if (time.length == 3) {
      timeinsec = time[0] * 60 * 60 + +time[1] * 60 + +time[2];
    } else if (time.length == 2) {
      timeinsec = time[0] * 60 + +time[1];
      console.log(time[0] * 60 + +time[1]);
    } else {
      timeinsec = time[0];
    }

    seturl(+timeinsec);
  };

  console.log(url);

  const handleViewResultClick = async () => {
    console.log("hello");
    try {
      const token = localStorage.getItem("loginDetails");
      const response = await fetch(
        `${apiBasePath}OpenMic/getOpenMicResult/${openMicData?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const responseData = await response.json();
      handleWinnersId();
      setShowWinners(prev => !prev)
    } catch (error) {
      console.error("Error fetching open mic result:", error);
    }
  };

  const handleWinnersId = async () => {
    try {
      const token = localStorage.getItem("loginDetails");
      const response = await fetch(
        `${apiBasePath}OpenMic/getDataById/${openMicData?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      const responseData = await response.json();
      setWinnersIdArray(responseData.data[0].winnersId);
    } catch (error) {
      console.error("Error fetching open mic result:", error);
    }
  };

  useEffect(() => {
    if (winnersIdArray.length > 0) {
      fetchWinnerNames();
    }
  }, [winnersIdArray]);

  const fetchWinnerNames = async () => {
    const winnerNames = [];
    try {
      const token = localStorage.getItem("loginDetails");
      for (const winnerId of winnersIdArray) {
        const response = await fetch(
          `https://api.storytent.in/api/openMic/getParticipantDataById/${winnerId}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
          }
        );
        const responseData = await response.json();
        if (responseData.status === "Success") {
          winnerNames.push(responseData.data[0].name);
        } else {
          console.error(
            "Failed to fetch winner details:",
            responseData.message
          );
        }
      }
      setWinners(winnerNames);
    } catch (error) {
      console.error("Error fetching winner details:", error);
    }
  };

  const fetchOpenMicData = async (openMicId) => {
    try {
      const response = await getOpenMicDataById2(openMicId);
      const openMicDetails = response.data[0];
      setOpenMicData(openMicDetails);

      // Process and set the date
      let dateStr = openMicDetails?.resultDate;
      let parts = dateStr.split("-");
      if (parts[2].length === 1) {
        parts[2] = parts[2].padStart(2, "0");
      }
      dateStr = parts.join("-");
      setDate(dateStr);

      // Determine if the result date has already passed
      const resultDateTime = new Date(`${openMicDetails.resultDate}T${openMicDetails.resultTime}:00`);
      const currentDateTime = new Date();

      // If the result date is in the past, show the view result button
      if (currentDateTime >= resultDateTime) {
        setShowViewResultButton(true);
      }
    } catch (error) {
      console.error("Error fetching open mic data:", error);
    }
  };

  return (
    <Layout>
      <div className={`openmic-container`}>
        <div className="player">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
            }}
          >
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "32px",

                "@media (min-width: 350px) and (max-width: 450px)": {
                  fontSize: "10px",
                  textAlign: "start",
                },
              }}
            >
              {openMicData?.name} {"   "}{" "}
            </h1>
          </div>
          <div className="youtube">
            {openMicData && (
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${openMicData?.videoLink}?start=${url}&rel=0`}
                title="YouTube video player"
                frameBorder="10"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{
                  borderRadius: "12px",
                  height: "87%",
                }}
              ></iframe>
            )}
          </div>
          <div
            onClick={() => setIsdescriptionopen(!isDescriptionOpen)}
            className="description"
          >
            <h1 className="description-heading">Timestamps</h1>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
              {!isDescriptionOpen ? (
                <IoIosArrowDown
                  onClick={() => setIsdescriptionopen(true)}
                  className=" down-icon"
                ></IoIosArrowDown>
              ) : (
                <RxCross2
                  onClick={() => setIsdescriptionopen(false)}
                  className="down-icon"
                ></RxCross2>
              )}
            </div>
          </div>

          {isDescriptionOpen && (
            <div>
              <hr></hr>
              <div className=" description-box">
                {/* <h1 className=" openmic-data">{openMicData.name}</h1> */}

                {/* <div className="view-section">
                <div className=" view-elemets">
                <span className=" number">4</span>
                <span>Likes</span>
                </div>
                <div className=" view-elemets">
                <span className=" number">96</span>
                <span>Views</span>
                </div>
                <div className=" view-elemets">
                <span className="number">Mar 29</span>
                <span>2024</span>
                </div>
              </div> */}

                <div className=" time-stamp">
                  <div className=" item-stamp-content">
                    {Timestamp.length > 0 &&
                      Timestamp?.map((data) => (
                        <div className="time-stamp-link">
                          <span onClick={() => changetosec(data.time)}>
                            {data.time}
                          </span>
                          <span className="item-stamp-text">{data.title}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!showViewResultButton && openMicData && (
          <p className="player1">
            Results in{" "}
            <Countdown
              date={new Date(`${openMicData.resultDate}T${openMicData.resultTime}:00`)}
              intervalDelay={0}
              precision={3}
              onComplete={handleCountdownComplete}
              renderer={renderer}
            />
          </p>
        )}
        {showViewResultButton && (
          <button
            className="player1"
            onClick={handleViewResultClick}
            style={{
              marginTop: "20px",
              borderRadius: "20px",
              backgroundColor: "#c81919",
              color: "white",
              padding: "10px",
              border: "none",
              cursor: "pointer",
            }}
          >
            View Result
          </button>
        )}
        {
          showWinners && (
        <WinnerContainer>

          {winners.map((winner, index) => (
            <p
              key={index}
              className="winner-name"
              style={{
                marginTop: "10px",
                color: "white",
                fontSize: "20px",
                textAlign:"center"
    //             marginTop: "10px",
    // width: "fit-content",
    // border: "1px solid green",
    // paddingLeft: "20px",
    // paddingRight: "20px",
    // fontSize: "20px",
    // color: "white",
    // textAlign: "center"
              }}
            >
              {winner}
            </p>
          ))}
        </WinnerContainer>

          )
        }

        <div style={{ height: "3vh" }}>
          {!copySuccess ? (
            <ShareButton
              onClick={() =>
                copyToClipboard(
                  `https://www.storytent.in/open-mic/${openMicData?._id}`
                )
              }
            >
              <span className="span">Share </span>
              <i className="fa fa-share default-cursor-point"></i>
            </ShareButton>
          ) : (
            <CopiedButton>Copyed!</CopiedButton>
          )}
        </div>

        {openMicData && <Poll openMicData={openMicData} />}
      </div>
    </Layout>
  );
};

export default OpenMic;

const CopiedButton = styled.button`
  padding-top: 2px;
  padding-bottom: 2px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50px;
  background-color: transparent;
  color: green;
  border: none;
  position: absolute;
  right: 12%;
  @media (min-width: 350px) and (max-width: 769px) {
  padding-right: 2px;
    right: 3%;
  }
`;
const WinnerContainer = styled.button`
  border: 1px solid red;
    border-radius: 20px;
    background-color: #3e6378;
    width: 76%;
    margin-top:20px;
    margin-bottom:20px;
`;
const ShareButton = styled.button`
  padding-top: 2px;
  padding-bottom: 2px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50px;
  background-color: #c81919;
  color: white;
  border: none;
  position: absolute;
  right: 12%;

  @media (min-width: 550px) and (max-width: 769px) {
    right: 3%;
  }

  @media (min-width: 350px) and (max-width: 550px) {
    /* Add your responsive styles here */

    right: 3%;

    .span {
      display: none;
    }
  }
`;
