import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { getAllMeetings } from "../utilities/ApiCalls";
import Slider from "react-slick";
import CustomSlider from "../../Componets/Customslider";
import moment from "moment";

const EventsMeet = () => {
  const [payload, setPayload] = useState([]);
  const payloadd = [
    {
      _id: "676d48ca7b424cb7efb202f8",
      sdkKey: "3PCfVxcLSZiDuwGHV_l1qA",
      sdkSecret: "9D5rGiyRBgOKSJ5tv299CjSzNi8Mhhyt",
      meetingNumber: "83916193706",
      title: "Anti-Matter",
      description:
        "Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us.",
      passWord: "8id4Ye",
      role: 0,
      userName: "",
      userEmail: "",
      registrantToken: "",
      zakToken: "",
      leaveUrl: "https://storytent.in/events",
      createdAt: "2024-12-26T12:15:06.598Z",
      updatedAt: "2024-12-26T12:17:43.876Z",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      sdkKey: "3PCfVxcLSZiDuwGHV_l1qA",
      sdkSecret: "9D5rGiyRBgOKSJ5tv299CjSzNi8Mhhyt",
      meetingNumber: "83916193706",
      title: "Anti-Matter",
      description:
        "Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us.",
      passWord: "8id4Ye",
      role: 0,
      userName: "",
      userEmail: "",
      registrantToken: "",
      zakToken: "",
      leaveUrl: "https://storytent.in/events",
      createdAt: "2024-12-26T12:15:06.598Z",
      updatedAt: "2024-12-26T12:17:43.876Z",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      sdkKey: "3PCfVxcLSZiDuwGHV_l1qA",
      sdkSecret: "9D5rGiyRBgOKSJ5tv299CjSzNi8Mhhyt",
      meetingNumber: "83916193706",
      title: "Anti-Matter",
      description:
        "Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us.",
      passWord: "8id4Ye",
      role: 0,
      userName: "",
      userEmail: "",
      registrantToken: "",
      zakToken: "",
      leaveUrl: "https://storytent.in/events",
      createdAt: "2024-12-26T12:15:06.598Z",
      updatedAt: "2024-12-26T12:17:43.876Z",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      sdkKey: "3PCfVxcLSZiDuwGHV_l1qA",
      sdkSecret: "9D5rGiyRBgOKSJ5tv299CjSzNi8Mhhyt",
      meetingNumber: "83916193706",
      title: "Anti-Matter",
      description:
        "Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us.",
      passWord: "8id4Ye",
      role: 0,
      userName: "",
      userEmail: "",
      registrantToken: "",
      zakToken: "",
      leaveUrl: "https://storytent.in/events",
      createdAt: "2024-12-26T12:15:06.598Z",
      updatedAt: "2024-12-26T12:17:43.876Z",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      sdkKey: "3PCfVxcLSZiDuwGHV_l1qA",
      sdkSecret: "9D5rGiyRBgOKSJ5tv299CjSzNi8Mhhyt",
      meetingNumber: "83916193706",
      title: "Anti-Matter",
      description:
        "Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us.",
      passWord: "8id4Ye",
      role: 0,
      userName: "",
      userEmail: "",
      registrantToken: "",
      zakToken: "",
      leaveUrl: "https://storytent.in/events",
      createdAt: "2024-12-26T12:15:06.598Z",
      updatedAt: "2024-12-26T12:17:43.876Z",
      __v: 0,
    },
    // {
    //   _id: "676d48ca7b424cb7efb202f8",
    //   sdkKey: "3PCfVxcLSZiDuwGHV_l1qA",
    //   sdkSecret: "9D5rGiyRBgOKSJ5tv299CjSzNi8Mhhyt",
    //   meetingNumber: "85992353782",
    //   title: "Anti-Matter",
    //   description:
    //     "Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us. Let's talk about what matters to us.",
    //   passWord: "9yW80Z",
    //   role: 1,
    //   userName: "Nakshatra Trivedi",
    //   userEmail: "Nakshara@gmail.com",
    //   registrantToken: "",
    //   zakToken: "",
    //   leaveUrl: "https://storytent.in/events",
    //   createdAt: "2024-12-26T12:15:06.598Z",
    //   updatedAt: "2024-12-26T12:17:43.876Z",
    //   __v: 0,
    // },
  ];
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await getAllMeetings();
        setPayload(res.data.meetings);
        console.log(res.data.meetings);
      } catch (error) {
        console.error(error);
      }
    };
    fetch();
  }, []);

  const handleJoinMeeting = (payloadIndex) => {
    history.push({
      pathname: "/Storytent-Meeting",
      state: { meetingConfig: payload[payloadIndex] },
    });
  };

  return (
    <Layout>
      <MainContainer>
        <ContentWrapper>
          <PageTitle>ANTI-MATTER</PageTitle>
          <CardsContainer>
            {payload.map((item, index) => {
              const formatDate = (dateString) => {
                return moment(dateString).format("h:mm A [at] Do MMM YYYY");
              };

              return (
                <EventCard key={index}>
                  <InfoSection>
                    <Title>{item.name}</Title>
                    <Timing>{formatDate(item.startTime)}</Timing>
                    {/* <Timing>{formatDate(item.createdAt)}</Timing> */}
                    <Description>{item.description}</Description>
                  </InfoSection>
                  <ActionSection>
                    <JoinButton onClick={() => handleJoinMeeting(index)}>
                      <ButtonText>Join meeting</ButtonText>
                    </JoinButton>
                  </ActionSection>
                </EventCard>
              );
            })}
          </CardsContainer>
        </ContentWrapper>
      </MainContainer>
    </Layout>
  );
};

export default EventsMeet;

// Styled Components
const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 120px); // Adjust based on your navbar/footer height
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://story-tent-testing.s3.ap-south-1.amazonaws.com/banner/r-form_1734339917460.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const PageTitle = styled.h1`
  color: white;
  text-align: center;
  font-family: "itim", sans-serif;
  margin: 0;

  font-size: 24px;
`;

const CardsContainer = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  gap: 20px;
  padding: 0 20px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
  }

  @media (max-width: 1440px) {
    width: 70%;
  }
  @media (max-width: 868px) {
    padding: 0 10px;
    width: 80%;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const EventCard = styled.div`
  width: 100%;
  min-height: 250px;
  display: flex;
  border: 1px solid white;
  border-radius: 14px;
  background: transparent;

  @media (max-width: 768px) {
    flex-direction: column;
    min-height: 280px;
  }

  @media (max-width: 480px) {
    min-height: 280px;
    border-radius: 8px;
  }
`;

const InfoSection = styled.div`
  flex: 0.7;
  background-color: #07262b;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 14px 0 0 14px;

  @media (max-width: 768px) {
    flex: 1;
    border-radius: 14px 14px 0 0;
  }

  @media (max-width: 480px) {
    border-radius: 8px 8px 0 0;
  }
`;

const ActionSection = styled.div`
  flex: 0.3;
  background-color: #1d241d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 14px 14px 0;

  @media (max-width: 768px) {
    min-height: 50px;
    border-radius: 0 0 14px 14px;
  }

  @media (max-width: 480px) {
    border-radius: 0 0 8px 8px;
  }
`;

const Title = styled.h2`
  color: white;
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 15px 0;
`;

const Timing = styled.p`
  color: white;
  font-size: 16px;
  margin: 0 0 10px 0;
`;

const Description = styled.p`
  color: white;
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
`;

const JoinButton = styled.button`
  padding: 7px 30px;
  border-radius: 10px;
  background-color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ButtonText = styled.span`
  font-family: "itim", sans-serif;
  font-weight: 700;
  color: black;
`;
