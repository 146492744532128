import { ZoomMtg } from "@zoom/meetingsdk";
import "./zoom.css";
import { useEffect, useState } from "react";
import Constant from "../Constant";
import { getTokenDetails } from "../utilities/CommonFunction";
import { getAnyProfileDetails } from "../utilities/ApiCalls";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

const ZoomMeeting = ({ payload }) => {
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);

  // First fetch user details
  useEffect(() => {
    const fetchUser = async () => {
      try {
        let token = localStorage.getItem("loginDetails");
        const { _id } = getTokenDetails(token);
        const res = await getAnyProfileDetails(_id);

        // Validate username length
        const userName = res.data.data.name;
        if (!userName || userName.length > 128) {
          setError("Username must be between 1 and 128 characters");
          return;
        }

        setUserDetails({
          userName: userName,
          userEmail: res.data.data.email,
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("Failed to fetch user details");
      }
    };

    fetchUser();
  }, []);

  // Only proceed with signature and meeting after user details are loaded
  useEffect(() => {
    if (userDetails && payload) {
      getSignature();
    }
  }, [userDetails, payload]);

  const getSignature = async () => {
    try {
      const req = await fetch(`${Constant.apiBasePath}zoom/generateSignature`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingNumber: payload.meetingNumber,
          role: 0,
          sdkKey: payload.sdkKey,
          sdkSecret: payload.sdkSecret,
        }),
      });

      if (!req.ok) {
        throw new Error(`Error: ${req.statusText}`);
      }

      const res = await req.json();
      if (!res.signature) {
        throw new Error("No signature found in the response");
      }

      startMeeting(res.signature);
    } catch (e) {
      console.error("Error fetching signature:", e);
      setError("Failed to generate meeting signature");
    }
  };

  function startMeeting(signature) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      document.getElementById("zmmtg-root").style.display = "block";
    }, 3000);

    ZoomMtg.init({
      leaveUrl: "https://storytent.in/",
      patchJsMedia: true,
      leaveOnPageUnload: true,
      success: (success) => {
        console.log("Init success:", success);

        ZoomMtg.join({
          signature: signature,
          sdkKey: payload.sdkKey,
          meetingNumber: parseInt(payload.meetingNumber),
          passWord: payload.passWord,
          userName: userDetails.userName,
          userEmail: userDetails.userEmail,
          tk: payload.registrantToken,
          zak: payload.zakToken,
          success: (success) => {
            console.log("Join success:", success);
            setLoading(false);
          },
          error: (error) => {
            console.log("Join error:", error);
            setError(error.message || "Failed to join meeting");
            setLoading(false);
          },
        });
      },
      error: (error) => {
        console.log("Init error:", error);
        setError(error.message || "Failed to initialize meeting");
        setLoading(false);
      },
    });
  }

  if (error) {
    return (
      <div
        style={{
          width: "100%",
          padding: "20px",
          color: "red",
          textAlign: "center",
          backgroundColor: "#fee2e2",
        }}
      >
        Error: {error}
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "gray",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontSize: "26px",
        }}
      >
        Joining Meeting...
      </div>
    );
  }

  return <div className="App" />;
};

export default ZoomMeeting;
