import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CreateMeet = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Hide Zoom root on component mount
    const zoomRoot = document.getElementById("zmmtg-root");
    if (zoomRoot) {
      zoomRoot.style.display = "hidden";
    }

    // Check for auth code in URL
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      handleZoomCallback(code);
    }
  }, [location]);

  const handleAuth = async () => {
    // const res = await axios.get("http://localhost:8083/api/zoom/auth");
    // const url = res.data.url;
    const url =
      "https://zoom.us/oauth/authorize?client_id=3PCfVxcLSZiDuwGHV_l1qA&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2FStorytent-Meeting";
    window.location.href = url;
  };

  const handleZoomCallback = async (code) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `http://localhost:8083/api/zoom/callback?code=${code}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("loginDetails")}`,
          },
        }
      );

      const { access_token, refresh_token, expires_in } = response.data.data;

      // Store tokens
      localStorage.setItem("zoom_access_token", access_token);
      if (refresh_token) {
        localStorage.setItem("zoom_refresh_token", refresh_token);
      }

      // Store expiration time
      const expirationTime = new Date().getTime() + expires_in * 1000;
      localStorage.setItem("zoom_token_expiry", expirationTime);

      setIsAuthenticated(true);
    } catch (error) {
      console.error("Token exchange failed:", error);
      setError("Failed to authenticate with Zoom. Please try again.");
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(
        "http://localhost:8083/api/zoom/createMeeting",
        {
          topic: "Team Meeting",
          start_time: "2024-11-28T10:00:00Z",
          duration: 30,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("loginDetails")}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  // Check if access token exists and is not expired
  const checkAuth = () => {
    const accessToken = localStorage.getItem("zoom_access_token");
    const tokenExpiry = localStorage.getItem("zoom_token_expiry");

    if (accessToken && tokenExpiry) {
      const isExpired = new Date().getTime() > parseInt(tokenExpiry);
      return !isExpired;
    }
    return false;
  };
  return (
    <div>
      {loading ? (
        <div style={styles.loadingText}>Authenticating with Zoom...</div>
      ) : error ? (
        <div style={styles.card}>
          <div style={styles.error}>{error}</div>
          <button
            onClick={handleAuth}
            style={{
              ...styles.button,
              ...(isHovered ? styles.buttonHover : {}),
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Try Again
          </button>
        </div>
      ) : isAuthenticated || checkAuth() ? (
        <h1>Lets create a meeting</h1>
      ) : (
        <div style={styles.card}>
          <button
            onClick={handleAuth}
            style={{
              ...styles.button,
              ...(isHovered ? styles.buttonHover : {}),
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Sign in with Zoom
          </button>
        </div>
      )}
    </div>
  );
};

export default CreateMeet;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
  },
  card: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "32px",
    width: "400px",
  },
  error: {
    backgroundColor: "#fde8e8",
    color: "#dc2626",
    padding: "16px",
    borderRadius: "4px",
    marginBottom: "16px",
  },
  button: {
    width: "100%",
    padding: "10px 24px",
    backgroundColor: "black",
    color: "white",
    border: "2px solid #3b82f6",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background-color 0.2s",
  },
  buttonHover: {
    backgroundColor: "#333333",
  },
  loadingText: {
    textAlign: "center",
    padding: "10px 0",
  },
};
