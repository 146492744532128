import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const SliderWrapper = styled.div`
  .slick-slide > div {
    height: 100%;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    > div {
      height: 100%;
    }
  }
`;

const SliderItemm = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  height: 250px;
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(8, 8, 8, 0.2);
  border: 1px solid white;
  border-radius: 14px;

  @media (max-width: 768px) {
    height: 250px;
  }

  @media (max-width: 570px) {
    height: 340px;
  }
  @media (max-width: 480px) {
    height: 370px;
    border-radius: 8px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  @media (max-width: 570px) {
    flex-direction: column;
  }
`;

const LeftDiv = styled.div`
  width: 70%;
  height: 100%;
  background-color: #07262b;
  padding: 15px;
  display: flex;
  padding-right: 10px;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 570px) {
    width: 100%;
  }
`;

const RightDiv = styled.div`
  width: 30%;
  height: 100%;
  background-color: #1d241d;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 570px) {
    width: 100%;
  }
`;
const Joinbutton = styled.div`
  width: auto;
  height: 50px;
  padding: 10px;
  border-radius: 10px;
  color: black;
  background-color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Title = styled.p`
  color: white;
  font-size: 22px;
  font-weight: 700;
`;
const Timing = styled.p`
  color: white;
  font-size: 16px;
`;
const Subtitle = styled.p`
  color: white;
  font-size: 16px;
`;

const CustomSlider = ({ settings, items, onClick }) => {
  return (
    <SliderWrapper>
      <Slider {...settings}>
        {items.map((item, index) => (
          <SliderItemm key={item._id}>
            <FlexContainer>
              <LeftDiv>
                <Title>Title: {item.title}</Title>
                <Timing>Timing: {item.createdAt}</Timing>
                <Subtitle>Description: {item.description}</Subtitle>
              </LeftDiv>
              <RightDiv>
                <Joinbutton onClick={() => onClick(index)}>
                  <span style={{ fontFamily: "itim" }}>Join meeting</span>
                </Joinbutton>
              </RightDiv>
            </FlexContainer>
          </SliderItemm>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default CustomSlider;
