import React, { useState, useEffect } from "react";
import { getAllMeetings } from "../utilities/ApiCalls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Layout from "../../Layout/Layout";
import styled from "styled-components";

const AntiMatterPage = () => {
  const [payload, setPayload] = useState([]);
  const payloadd = [
    {
      _id: "676d48ca7b424cb7efb202f8",
      meetingTime: "TIME- 11:00 am",
      title: "CHIEF GUEST ADDRESS",
      description:
        "The Chief Guest, Prof. Milind Sudhakar Marathe, Chairman of the National Book Trust India, will deliver an inspiring address, sharing insights on literature's evolving role in shaping global narratives.",
      imageUrl:
        "https://s3-alpha-sig.figma.com/img/33c1/126b/711b4de392d12f831f79f74ac1b21ac4?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=erVT5kLqlh-H914DXvqq8JWPJFh5aR7Q2pcKl86JTPuaxzuBfLfqRsxa26BuX4XzTkwgRzvU-Oe8vK83fi3A1vR7Ldgd1p-N1aPlXrnO6g77lMxEXQnEBySglaJPZV7fcFwc57woZP0lZjFxdJV0Az9LsuORV7UZLR00AkQp91QGgI6i9T8Rl1yxsFLz3IuhWdXSaWJJcpv84fuq5am4du-mrhCQslzBPcXhfVTn3jEVb4cHGWFbENu6soNJ8ZQ7VZ1R0ORMfeTS1oOL35~nKlY3dIEbJHyNfwGfJzy2q72JqxJnPIFI1P-0JJgMFwcVlygOwf0b03s3jnTrBuY6Pw__",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      title: "PANEL DISCUSSION",
      description:
        "The panel discussion will bring together diverse experts, including academics, authors, and environmentalists, to explore the future of literature, breaking traditional storytelling paradigms and inspiring innovative approaches to writing.",
      meetingTime: "TIME- 11:30 am",
      imageUrl:
        "https://s3-alpha-sig.figma.com/img/9847/b461/7fc22fdfadf7dcea345adec979fe1633?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=f4lUd7vBCairZM7ETInXY5qTmVnjO9778YcMrw0fReTtfIX59H6JkTS1vmE001q-TYuEnLOl0WbGD9ja0xCPPWxu96ToKpoxux8nUEmlRmQXoWIKLrQdufrBmit5m-EJTYqcCuZpEnR--HaKuLeavnjjEwgFegY4Rx-vZDCM5h59eZn186edPuO2Fgiaq-WOUYpaUlsokii6s3LJHwLY93GzWTfNX8sjIW2tilAOsAa~5FxlcW8pQqDSUHhgwBiH4Cz8Z-bwaldpUS9HBfte1M1QhgydyXaQGDrjTni573kGmMyPeTBqQ~IxanquO7r57YUNhWWRHWMMksrQpCSo~g__",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      title: "‘JUGALBANDI’ PERFORMANCE",
      description:
        "The Jugalbandi session will feature a dynamic clash of creativity, where poets and rappers engage in a powerful exchange of words, blending lyrical prowess with raw rhythm, pushing the boundaries of expression.",
      meetingTime: "TIME- 12:45 am",
      imageUrl:
        "https://s3-alpha-sig.figma.com/img/f05d/b095/f69a544f1e015c5e5f04281e3d55189d?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GLNTPbihYB-DVDGD-DinoGH34Le2dBnkGTrvf4hjnuUn138zEWWfC2DDTtvLi3MkBLMFJsF4r1lbqa6ealF1uL3XBnyb574Y5P7Ry8fTLAYVbm0uKhftVeVCLWlZpRQfFlnKf3tbOy2bPj8MHtLvRC0j--sIiY9BNsCqUHbjR0GpRSO3ldZ4E~4ibK0VDGTe8LkIqrQVZbktqrDOUWOgtUVEOYZ6SawWPF7V0bXBEEodwDCQnov~cNkFOut54m9PKNTIdxHttIxZOjXG3oNC2RZrDgnousE0CpdAHQFJIL2K3E63CjQoUzoM-BSu76P0S3wqHppjYygpNEd1FyJgDA__",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      title: "POETRY CONTEST",
      description:
        "The Poetry Contest will showcase exceptional talent, where participants present their finest poems, exploring diverse themes and styles, competing for the title and an opportunity to share their voice with a wider audience.",
      meetingTime: "TIME- 1:00 pm",
      imageUrl:
        "https://s3-alpha-sig.figma.com/img/240d/43fb/087f593de39acd300bdfe0bfd5aa80f4?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=V3-p1fTShfWlD61DAXcLNOvgczOYNKYR~gRNF43PFAPWT9eAqpD1yloc8YGxP27Pmb-gOHfPdE2dJaVxim35dj~-Jl2SMEsPXyTukTW4pVuDxmxuO~rzZuF~d7v4FaWI1eKz5KFdjKg1fA~eP8gSqc0dPmkE0yDRoce2dH~hh8y17ohlpL7j9raI62lWOpofifAaZ84U85VA5u8d7BQFn87T3LVLHFEjGd7LWBQu7zeSDIXQgABejdEY5rYy2Z8aomKD4mJqaUYnaQ74zRsb3Y~J3FOo54fsiDhsu-MyepIZLOMcIRtLbEXrMYu7zXCgEjd-nRhs1oOl-cB8irp03g__",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      title: "ENGLISH WORKSHOP",
      description:
        "The workshop in English, led by Dr. Prantik Banerjee, will help participants enhance their poetic skills by integrating sensory experiences and intuitive expression into their writing.",
      meetingTime: "TIME- 4:30 pm",
      imageUrl:
        "https://s3-alpha-sig.figma.com/img/ad5b/bc0a/6911c6926a51411136954c2fe53496d5?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=R~sBzI1sGwNjqdKwuvagI7OQMxd1FsIhlvo2kbHHYyLOocv29Lhvq6z0yMxko0KavHBKjcr7reAly3mB0CerqbUJswrmZpc2Q5cWxtA44SCcqqgM~YLKYz-CdyHKgW1Bc~d~sH~oBqLzt17pFw36XzUsh9GIN5fs~3~AvztdAywTcoxXDRODf2HuB7rO3gdDXHkgRnXShEd-Q~-DsQmgUCbA5AzzoJC5DgXZBCov6aKhZj0nBySdKshXvdmjW9ocOdOzs2pwOoC3olEq6SbSodMu47eGUZ2haxDdhNoIhPy87F1qFBfgRecSe4ZneyHJN2FMSpYoIcRhL0-Wqe6rDA__",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      title: "URDU WORKSHOP",
      description:
        "The Urdu workshop, led by Dr. Tejinder Singh Rawal, will focus on the basics of Urdu language and help participants refine their writing skills, enhancing their ability to express ideas with precision and elegance.",
      meetingTime: "TIME- 4:30 pm",
      imageUrl:
        "https://s3-alpha-sig.figma.com/img/303a/2be8/ab44896109472bb390d002bfc835bd0e?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MX-UofLF-C88lxcvbpQLVgiGr6k8t7zu3bmuxYQ-daXXfxrEVCFku~203PoWWWd2QWq~Zfuo86-f3mWViMtRmfCfTfbIQtaQM9g~DTW2HP6EbWfSbEt5lxVoOGniIcODk6O-Y9UcesuFJWGT68a8n9aWIPuW7vOhwcfEY24M7aM55TYVO5e1YiHctAkiuCHwA9jkw3~QziGwgXnQzEto2zQJRVecrgMQbjCfCOsP1NcVS3UDvVb69lMuhYziQyglJS5MphXlV23tRcjVOehV1HJckr811e7ibxeGuhpQ59vjo-FjP~x2m6lhjRQfA3kJ13qSDYnLG4cawDqf4ihmMg__",
      __v: 0,
    },
    {
      _id: "676d48ca7b424cb7efb202f8",
      title: "MUSICAL PERFORMANCE",
      description:
        "SonicSphere will feature two members of the band The Leading Drops, who will perform their incredible music while sharing insights into their creative process, lyricism, and the art of collaboration, offering a unique behind-the-scenes experience.",
      passWord: "8id4Ye",
      meetingTime: "TIME- 5:45 pm",
      imageUrl:
        "https://s3-alpha-sig.figma.com/img/d18b/22b8/2e309d34791bdc18f3f09977da9e59ce?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iyRPrt6vsj-jKlYwSVIALY14AXYzsuxNMQTxtBK0MNvgTW-a7baJ3-H1KnvqSIscORB7EzvAv1i49lQ10fBgJGMTWIKbR5vdpjazpo6XLATgiVyrmquOzVYHcYy3eJPBlQmt2Kf-LmvPP7eOBvC7n9~q3RuHWPGfKhKdSxKsS6w~-ApWCb4ty1X3bzTqldonZp7Wtz1QEDsIP-BCrjyDTLw4UoHUJXwXUZjWU-PGdAYEBo6xZDobUg-KhodoiwqFu-LvOociU~pYbjO8LO3IQcV0q20TH8fNBOd4Jq~bovgIUhPh570FHe74-JjNJVHcKdmgylvqhwMxPiCPfxMcIA__",
      __v: 0,
    },
  ];
  const history = useHistory();
  const meeting = {
    sdkKey: "3PCfVxcLSZiDuwGHV_l1qA",
    sdkSecret: "9D5rGiyRBgOKSJ5tv299CjSzNi8Mhhyt",
    meetingNumber: "83916193706",
    passWord: "8id4Ye",
    role: 0,
    userName: "",
    userEmail: "",
    registrantToken: "",
    zakToken: "",
    leaveUrl: "https://storytent.in/events",
  };

  useEffect(() => {
    const checklogged = async () => {
      const token = await localStorage.getItem("loginDetails");
      if (!token) {
        // Save the current path to localStorage before redirecting
        localStorage.setItem("redirectAfterLogin", window.location.pathname);
        history.push("/login");
      }
    };
    checklogged();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await getAllMeetings();
        setPayload(res.data.meetings);
        console.log(res.data.meetings);
      } catch (error) {
        console.error(error);
      }
    };
    fetch();
  }, []);

  const handleJoinMeeting = () => {
    console.log(payload[0]);
    history.push({
      pathname: "/Storytent-Meeting",
      state: { meetingConfig: payload[0] },
    });
  };

  return (
    <Layout>
      <FullPage>
        <BannerContainer>
          <BannerImage
            src="https://s3-alpha-sig.figma.com/img/d8da/1d0a/28d6cccd5ad6c5ffd7412742cd9e09bf?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FJVZm6lf1M883PO0c1JtZjdqt4gjbYG3W5vmhwiAYAgf4-4ps8AIDDx8zhXwLOaa5so0VjkbEw8qL9O4tyWPi0EWWgz9lJI~SN5x5nF1fnAm3Q3LKtCWiKxHayCcVeKCmb6jm1QMhQk37-QY0tgkFdzaTTWGiQeR6qOWAFu0Gry62f~g~IB3miS7p9-pDTrEbcT2nyeFUkmfdG~8lAUqGANfVwNB~T--oeAKlVILOTyqGFgGhBoVeVS0D-zsfA8lo0LifE-uZXsxA0prl5ahaDaO~~eRRG2WIRiIV5t8V71LXmKSw7MDZ0Zc0BaNv~AkK5IoC~-jk8Lsj9uVKG7eZw__"
            alt="Banner"
          />
        </BannerContainer>
        {/* <h1
          style={{ textAlign: "center", color: "white", marginBottom: "20px" }}
        >
          ANTI-MATTER
        </h1>
        <SeperaterLine /> */}

        {/* <CenterContainer>
          <CentralCard>
            <CardImage
              src="https://story-tent-testing.s3.ap-south-1.amazonaws.com/banner/r-form_1734339917460.jpg"
              alt="Meeting thumbnail"
            />
            <CardContent>
              <CardTitle>Welcome to Anti-Matter</CardTitle>
              <CardDescription>
                Discover the universe's secrets, uncover the mysteries, and
                explore the infinite possibilities.
              </CardDescription>
              <JoinButton>Join Meeting</JoinButton>
            </CardContent>
          </CentralCard>
        </CenterContainer> */}

        <ButtonConatiner>
          <CircleJoinButton>
            <JOIN onClick={handleJoinMeeting}>JOIN</JOIN>
          </CircleJoinButton>
        </ButtonConatiner>
        <Events>
          <Header>
            <H1>EVENTS</H1>
          </Header>
          <GridLayout>
            {payloadd.map((meeting, index) => (
              <GridItem key={index}>
                <GridCard>
                  <CardImage
                    src={`${meeting.imageUrl}`}
                    alt="Meeting thumbnail"
                  />
                  <CardContent>
                    <CardTitle>{meeting.title}</CardTitle>
                    <CardTiming>{meeting.meetingTime}</CardTiming>
                    <CardDescription>{meeting.description}</CardDescription>
                    {/* <JoinButton onClick={() => handleJoinMeeting(index)}>
                    Join Meeting
                  </JoinButton> */}
                  </CardContent>
                </GridCard>
              </GridItem>
            ))}
          </GridLayout>
        </Events>
      </FullPage>
    </Layout>
  );
};

export default AntiMatterPage;

// const FullPage = styled.div`
//   width: 100%;
//   min-height: 100vh;
//   padding-bottom: 2rem;
//   background-color: #1a56a2;
// `;
//   background-color: #573184;

const CenterContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const ButtonConatiner = styled.div`
  width: 100vw;
  height: 285px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    height: 250px;
  }
  @media (max-width: 550px) {
    height: 150px;
    margin-bottom: 10px;
  }
`;

const CircleJoinButton = styled.div`
  width: 170px;
  height: 170px;
  border-radius: 50%;
  font-family: "Cute Font", serif;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }

  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 15%,
    rgba(242, 217, 239, 1) 33%,
    rgba(192, 75, 181, 1) 63%
  );
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 600px) {
    width: 130px;
    height: 130px;
  }
`;

const JOIN = styled.h1`
  font-size: 37px;
  font-weight: 400;
  text-align: center;
  font-family: "Bruno Ace", serif;
  color: rgba(0, 0, 18, 1);
  line-height: 77.18px;
  font-style: normal;

  @media (max-width: 768px) {
    font-size: 37px;
  }
  @media (max-width: 600px) {
    font-size: 30px;
  }
`;

const CentralCard = styled.div`
  width: 80%;
  max-width: 800px;
  background-color: #373333;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  }
`;
const FullPage = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 2rem;
  background-color: #000012;
`;
const SeperaterLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  margin: 2rem 0;
`;

const GridContainer = styled.div``;

const BannerContainer = styled.div`
  width: 100%;
  height: 53vh;
  overflow: hidden;
  margin-bottom: 2rem;

  @media (max-width: 1014px) {
    height: 50vh;
  }
  @media (max-width: 768px) {
    height: 40vh;
  }
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const Events = styled.div`
  width: auto;
  height: 100%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const H1 = styled.h1`
  font-size: 110px;
  font-weight: 400;
  text-align: center;
  font-family: "Cute Font", serif;

  background: radial-gradient(
    circle,
    rgba(255, 254, 255, 1),
    rgba(227, 175, 222, 1),
    rgba(192, 75, 181, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* This makes the text transparent to show the gradient */
  background-clip: text; /* For non-webkit browsers */
  color: transparent;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 110px;
  }
  @media (max-width: 600px) {
    font-size: 80px;
  }
`;
const Header = styled.div`
  width: 87%;
  height: 130px;
  display: flex;
  justify-content: center;
  background-color: #170135;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    height: 80px;
  }
`;
const GridLayout = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
  gap: 2rem;

  /* Base styling for mobile */
  grid-template-columns: repeat(1, minmax(300px, 1fr));
  @media (max-width: 600px) {
    padding: 0 0;
    width: 86%;
  }

  /* Tablet view */
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }

  /* Desktop view */
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(300px, 1fr));

    /* Special handling for 7 items */
    &:has(> :nth-child(7)) {
      > :nth-child(6) {
        grid-column: 2 / 3;
        grid-row: 2;
      }
      > :nth-child(7) {
        grid-column: 2 / 3;
        grid-row: 3;
        max-width: 380px;
        justify-self: center;
      }
    }

    /* Handling for other numbers of items */
    &:has(> :last-child:nth-child(3n-1)):not(:has(> :nth-child(7))) {
      > :last-child {
        grid-column: 2 / 3;
      }
    }

    &:has(> :last-child:nth-child(3n-2)):not(:has(> :nth-child(7))) {
      > :nth-last-child(2),
      > :last-child {
        max-width: 380px;
        justify-self: center;
      }
      > :nth-last-child(2) {
        grid-column: 1 / 2;
      }
      > :last-child {
        grid-column: 2 / 3;
      }
    }
  }
`;

const GridItem = styled.div`
  min-width: 300px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px rgba(50, 44, 44, 0.1);
  }
`;

const GridCard = styled.div`
  width: 100%;

  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 256px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 0.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h1`
  font-size: 44px;
  line-height: 33px;
  margin: 0 0 0.2rem 0;
  font-family: "Cute Font", serif;

  background: radial-gradient(
    circle,
    rgba(255, 254, 255, 1),
    rgba(227, 175, 222, 1),
    rgba(192, 75, 181, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* This makes the text transparent to show the gradient */
  background-clip: text; /* For non-webkit browsers */
  color: transparent;
`;

const CardTiming = styled.p`
  font-size: 30px;
  line-height: 24.12px;
  font-family: "Cute Font", serif;

  background: radial-gradient(
    circle,
    rgba(255, 254, 255, 1),
    rgba(227, 175, 222, 1),
    rgba(192, 75, 181, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* This makes the text transparent to show the gradient */
  background-clip: text; /* For non-webkit browsers */
  color: transparent;
`;
const CardDescription = styled.p`
  line-height: 24.6px;
  color: white;
  font-family: "Bruno Ace", serif;
  margin: 0 0 1.5rem 0;
  flex: 1;
  font-size: 15px;
`;

const JoinButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #ecb6e5;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;
`;
