import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";

const UserAccess = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("loginDetails");
  const history = useHistory();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Component {...props} />;
        } else {
          // Save the current path to localStorage before redirecting
          localStorage.setItem("redirectAfterLogin", props.location.pathname);

          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default UserAccess;
